import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ProemanagerService } from '../proemanager.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  itemsx: MenuItem[];
  items: MenuItem[];
  display = false;
  url:any;
  nome: string
  displayn = false;
  constructor(private service: ProemanagerService,private router: Router) { 
    this.items = [
        {
            icon:'pi pi-fw pi-bars',
            label:'List',
            command: (event) => {
              this.display = true;
              }
        }    
  ];

  this.itemsx = [{
    label: '',
    items: [{
        label: 'Meus dados',
        icon: 'pi pi-user',
        command: (event) => {
          this.service.currentvalido.subscribe(dados => {
            console.log(dados)
            if (dados == 'true') {
              this.display = false;
              this.router.navigateByUrl('/minhaconta');
            }
            else {
              this.displayn = true
            }
          });
          }
    },
    {
        label: 'Homologações',
        icon: 'pi pi-copy',
        command: (event) => {
          this.service.currentvalido.subscribe(dados => {
            console.log(dados)
            if (dados == 'true') {
              this.display = false;
              this.router.navigateByUrl('/myhomols');
            }
            else {
              this.displayn = true
            }
          });
          }
    },
    {
      label: 'Dimensionador',
      icon: 'pi pi-cog',
      command: (event) => {
        this.service.currentvalido.subscribe(dados => {
          console.log(dados)
          if (dados == 'true') {
            this.display = false;
            this.router.navigateByUrl('/dimensionador');
          }
          else {
            this.displayn = true
          }
        });
        }
  },
    {
      label: 'Débitos',
      icon: 'pi pi-credit-card',
      command: (event) => {
        this.service.currentvalido.subscribe(dados => {
          console.log(dados)
          if (dados == 'true') {
            this.display = false;
            this.router.navigateByUrl('/debitos');
          }
          else {
            this.displayn = true
          }
        });
        }

  }
    ]},
    {
        label: 'Solicitar',
        items: [{
            label: 'Homologação',
            icon: 'pi pi-external-link',
            command: (event) => {
              this.service.currentvalido.subscribe(dados => {
                console.log(dados)
                if (dados == 'true') {
                  this.display = false;
                  this.router.navigateByUrl('/newhomol');
                }
                else {
                  this.displayn = true
                }
              });
              }
    
        },
        {
            label: 'Vistoria',
            icon: 'pi pi-info-circle',
            command: (event) => {
              this.service.currentvalido.subscribe(dados => {
                console.log(dados)
                if (dados == 'true') {
                  this.display = false;
                  this.router.navigateByUrl('/vistoria');
                }
                else {
                  this.displayn = true
                }
              });
              }
        }
        ,
        {
            label: 'Clientes',
            icon: 'pi pi-users',
            command: (event) => {
              this.service.currentvalido.subscribe(dados => {
                console.log(dados)
                if (dados == 'true') {
                  this.display = false;
                  this.router.navigateByUrl('/climanager');
                }
                else {
                  this.displayn = true
                }
              });
              }
        }
    ]}
    ,
    {
        label: 'Curta',
        items: [{
            label: 'Instagram',
            icon: 'pi pi-thumbs-up',
            command: (event) => {
              window.open('https://www.instagram.com/solluxengenharia/', '_blank');
              }
        },
        {
            label: 'Linkedin',
            icon: 'pi pi-thumbs-up',
            command: (event) => {
              window.open('https://www.linkedin.com/company/solluxengenharia/?viewAsMember=true', '_blank');
              }
        },
        {
            label: 'Facebook',
            icon: 'pi pi-facebook',
            command: (event) => {
              window.open('https://www.facebook.com/SolluxEngenharia/', '_blank');
              }
        }
    ]}
];
  }

  sidebar(){
    this.display = true;
  }
  ngOnInit(): void {
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.url && dados !== '') {
        this.url = dados;
      }
    });
}

}
