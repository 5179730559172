import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ProemanagerService } from '../proemanager.service';
declare var FirebasePlugin;
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-myhomols',
  templateUrl: './myhomols.component.html',
  styleUrls: ['./myhomols.component.css']
})
export class MyhomolsComponent implements OnInit {
  todashomologacoes:any;
  id = '';
  validaversao:any;
  displayx = false;
  notificacao: any
  testMarkup:SafeHtml;
  htmlString='';
  meutoken: string;
  displayBasic = false;
  decodedToken: any;
  messageArray: Array<{ user: String, textmsg: String, tipo:String}> = [];
  constructor(private renderer: Renderer2,private service: ProemanagerService,private router: Router,
    private firebase: FirebaseX) {
      this.renderer.listen('document', 'deviceready', () => {
        FirebasePlugin.onMessageReceived(( notification ) => {
          this.notificacao = notification
        });
        this.firebase.getToken()
        .then(token =>
          {
            this.meutoken = token;
            this.checatok()
          });
      });
     }

     checatok(){
      this.service.upasubnot({token: this.meutoken, ID: this.id})
        .subscribe(dados => {
          if (dados ) {
          }}
          );

      }
    
  
  getvalue(a:any){
    return a.target.value
  }
  ngOnInit(): void {
    this.service.versao({ver:'1.5.0' })
    .subscribe(dados => {
    if (dados !== this.validaversao) {
      this.validaversao = dados;
      if(this.validaversao.atualiza === 'atualiza')
      this.displayx = true;
    }
  })
    this.service.currentid.subscribe(dados => {
      if (dados) {
        this.id = dados;
        this.puxahomologacoes();
      }
    });
  }
  goplay(){
    window.open("market://details?id=com.AppExdev.datasolmobile","_self")
  }
  puxahomologacoes() {
    this.service.myhomols({ ID: this.id })
      .subscribe((data: any[]) => {
        if (data !== this.todashomologacoes) {
          this.todashomologacoes = data;

        }
      });
  }


  detail(homol:string){
    this.service.changehomolfilter(homol);
    this.router.navigateByUrl('/detailview');
  }

}
