import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dropbox } from 'dropbox';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DbxAuth } from '../configs';
import { FilesService } from '../files.service';
import { ProemanagerService } from '../proemanager.service';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { ChatService } from '../chat.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  public dbxAuth: DbxAuth;
  private subscription: Subscription;
  dbcAutho = [];
  autorizado = 'true';
  // tslint:disable-next-line: max-line-length
  urlautorizada =
    'http://localhost:4200/auth:now#access_token=z_fOSBgdnYAAAAAAAAABZX4LmH2EZ4aVH4dk5mnj_5PudG9iXhnxbk5IgwkyLqqC&token_type=bearer&uid=2555201616&account_id=dbid%3AAADJxkR7klUpJ1Af9ffMdYIGWY1VMIWcNk8';
  // tslint:disable-next-line: align
  acesstok: any;
  isSpinning = true;

  uploader: FileUploader;
  enviado = false;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  validaupload = 'false';
  ultprogup: number;

  podeupar = false;
  chatselc = [];
  meunome = '';
  chatsx = [];
  idcliente = '';
  user: String;
  room: String;
  currentCota = '';
  cotaid = '';
  responsavel = '';
  dbxau: any;
  urlcompartilhadax = '';
  novocaminho: string;
  getpath = '';
  filteredhomol: any;
  messageText: String;
  textnew = '';
  comentarios = [];
  comment: string;
  pic = '';
  clienteatual: string;
  homolsect: string;
  messageArray: Array<{ user: String; textmsg: String; tipo: String }> = [];
  public messageSeen(event: any) {
    console.log(event);
  }
  constructor(
    private service: ProemanagerService,
    private filesService: FilesService,
    private authService: AuthService,
    private router: Router,
    private _chatService: ChatService,
    private spinner: NgxSpinnerService
  ) {
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe((res) => (this.response = res));
    this._chatService
      .newUserJoined()
      .subscribe((data) => this.messageArray.push(data));

    this._chatService
      .userLeftRoom()
      .subscribe((data) => this.messageArray.push(data));

    this._chatService
      .newMessageReceivedx()
      .subscribe((data) => this.apresentamsg(data));
  }

  getshare(caminho) {
    this.acesstok.sharingCreateSharedLink({ path: caminho }).then(
      (response) => {
        this.novocaminho = response.url;
        this.novocaminho = this.novocaminho.substring(
          0,
          this.novocaminho.length - 1
        );
        this.urlcompartilhadax =
          this.novocaminho.replace(
            'https://www.dropbox.com',
            'https://dl.dropboxusercontent.com'
          ) + '1';
        this.messageText = this.urlcompartilhadax;
        //this.sendMessage('file');
      },
      (error) => {
        console.log(error);
      }
    );
  }
  nova(a) {
    var content = $('#my-contenteditable-div').html();
    this.textnew = content.replace('&nbsp;', '');
    console.log(this.textnew);
  }
  asyncUpload() {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      this.acesstok
        .filesUpload({
          path:
            '/aplicativos/cotacoes/' +
            this.cotaid +
            '/arquivoschat' +
            '/' +
            this.uploader.queue[0]['_file'].name,
          contents: this.uploader.queue[0]['_file'],
        })
        // tslint:disable-next-line: only-arrow-functions
        .then((response) => {
          this.enviado = true;
          this.getpath = response.path_lower;
          this.uploader.queue[0]['isUploaded'] = true;
          this.uploader.queue[0]['isSuccess'] = true;
        })
        .catch((error) => reject())
        .finally(() => this.getshare(this.getpath));
    });
  }

  filesect(a) {
    this.spinner.show();
    this.asyncUpload();
  }

  clicabotao() {
    let element: HTMLElement = document.getElementById(
      'file-input'
    ) as HTMLElement;
    element.click();
  }

  progresso() {
    this.ultprogup = 100 / this.uploader.queue.length;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.uploader.progress = this.ultprogup * (i + 1);
      }
    }
    if (this.uploader.progress === 100) {
      this.validaupload = 'true';
    }
  }

  estouon(a) {}
  estouof(a) {}

  dowload(link) {
    window.open(link, '_self');
  }
  join() {
    this._chatService.joinRoom({ user: this.meunome, room: this.room });
    //this.enviasinal();
  }
  leave() {
    this._chatService.leaveRoom({ user: this.meunome, room: this.room });
  }

  ngOnInit() {
    this.clienteatual = localStorage.getItem('clienteatual');
    this.homolsect = localStorage.getItem('homolselecionada');

    this.service.currentid.subscribe((dados) => {
      if (dados !== this.idcliente && dados !== '') {
        this.idcliente = dados;
      }
    });
    this.service.currentNome.subscribe((dados) => {
      if (dados !== this.meunome && dados !== '') {
        this.meunome = dados;
      }
    });
    this.service.currentHomol.subscribe((dados) => {
      if (dados) {
        this.filteredhomol = dados;
      }
    });
    this.subscription = this.authService
      .getAuth()
      .subscribe((auth) => (this.dbxAuth = auth));
    this.acesstok = new Dropbox({ accessToken: this.dbxAuth.accessToken });
    // Begin authentication process if credentials not found
    if (this.autorizado === 'true') {
      const authUrl = this.router.url;
      const parameters = authUrl.split('#')[1] || '';
      if (this.autorizado === 'true') {
        const arrParams = parameters.split('&') || [];
        if (this.autorizado === 'true') {
          const authObj: DbxAuth = { isAuth: false };
          // tslint:disable-next-line: prefer-for-of
          authObj.accessToken =
            'z_fOSBgdnYAAAAAAAAABWcrt2Kyp4a5Cnsc599vY5SEuzdjYKAfgqYb-Bho2qLP2';
          authObj.tokenType = 'bearer';
          authObj.uid = '2555201616';
          authObj.accountId = 'dbid:AADJxkR7klUpJ1Af9ffMdYIGWY1VMIWcNk8';
          if (
            authObj.accessToken &&
            authObj.tokenType &&
            authObj.uid &&
            authObj.accountId
          ) {
            authObj.isAuth = true;
            this.dbxAuth = authObj;
          }
        }
      }

      // Store credentials into Auth-service and into localStorage
      if (this.dbxAuth.isAuth) {
        this.authService.storeAuth(this.dbxAuth);
        this.podeupar = true;
      }
    } else {
    }
    this.user = this.meunome;
    this.room = this.filteredhomol;
    this.join();
    this.chatx();
  }

  chatx() {
    this.service
      .puxacoment({ ID: this.filteredhomol })
      .subscribe((data: any[]) => {
        if (data) {
          console.log(data);
          this.chatsx = data;
          this.chatselc = data;
        }
      });
  }

  apresentamsg(a) {
    console.log('mostra apresentamsg')
    console.log(a)
    this.join()
    if(a.idono !== '483dc174-72c9-4290-bade-2bdc6b39e45a'){
      this.chatsx.push(a);
      this.enviacomenta()
    }
    if(a.idono === '483dc174-72c9-4290-bade-2bdc6b39e45a'){
      this.chatsx.push(a)
    }
  }

  ngOnDestroy() {
    this._chatService.leaveRoom({ user: this.idcliente, room: 'cotafacil' });
    //this._chatService.leaveRoom({ user: this.user, room: this.room });
    //this._chatService.leaveRoom({ user: this.idcliente, room: 'cotafacil' });
  }

  enviacoment() {
    this.service.currenturl.subscribe((dados) => {
      if (dados) {
        this.pic = dados;
      }
    });
    const dateinici = new Date(Date.now()).toLocaleString('pt-BR');
    this.service
      .novocomment({
        msg: this.messageText,
        idono: this.idcliente,
        hl: this.filteredhomol,
        data: dateinici,
        lida: '0',
        nome: this.meunome,
        urlpic: this.pic,
      })
      .subscribe((dados) => {
        if (dados) {
          console.log(dados);
          this.comentarios = [];
          this.chatx();
          ///this.enviaemail()
          if (dados['status'] === 'homologado') {
            console.log('inserido');
            this.comentarios = [];
            //this.enviacomenta();
            this.sendMessage('text')
            this.chatx();
          }
          if (dados['status'] === 'erro') {
            console.log('não inserido');
            this.comentarios = [];
            this.chatx();
          }
        }
      });
  }
  sendMessage(a) {
    this.join();
    this.service.currenturl.subscribe((dados) => {
      if (dados) {
        this.pic = dados;
      }
    });
    const dateinici = new Date(Date.now()).toLocaleString('pt-BR');
    this._chatService.sendMessage({
      msg: this.messageText,
      idono: this.idcliente,
      hl: this.filteredhomol,
      data: dateinici,
      lida: '0',
      nome: this.meunome,
      urlpic: this.pic,
      room:this.filteredhomol,
      wpp: 'this.wpp'
      });
    this.messageText = ""
  }
  enviacomenta() {
    var grupo = '553892515427-1612924425';
    var fones = [
      '5538992515427',
      '5538991437790',
      '553899666526',
      '5538988271752',
    ];
    //for (let i = 0; i < fones.length; i++) {
    this.service
      .sendtext({
        sessionName: 'sollux',
        number: grupo,
        text:
          '⚠️  O parceiro ' +
          '*' +
          this.meunome +
          '*' +
          ' enviou uma mensagem para o suporte técnico da homologação ' +
          '*' +
          this.homolsect +
          ' - ' +
          this.clienteatual +
          '*' +
          ' responda sempre de forma cordial,  com bom dia, boa tarde. ' ,
      })
      .subscribe((dados) => {
        if (dados) {
          console.log(dados);
        }
      });
    // }
  }
}
