import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProemanagerService } from '../proemanager.service';

@Component({
  selector: 'app-climanager',
  templateUrl: './climanager.component.html',
  styleUrls: ['./climanager.component.css']
})
export class ClimanagerComponent implements OnInit {
  todosclientes:any;
  id = '';
  validaversao:any;
  displayx = false;
  notificacao: any
  testMarkup:SafeHtml;
  htmlString='';
  meutoken: string;
  displayBasic = false;
  constructor(private router: Router,private service: ProemanagerService,) { }

  getvalue(a:any){
    console.log(a.target.value)
    return a.target.value
  }
  ngOnInit(): void {
    this.service.versao({ver:'1.1.0' })
    .subscribe(dados => {
      console.log(dados)
    if (dados !== this.validaversao) {
      this.validaversao = dados;
      if(this.validaversao.atualiza === 'atualiza')
      this.displayx = true;
    }
  })
    this.service.currentid.subscribe(dados => {
      if (dados !== this.id && dados !== '') {
        this.id = dados;
        console.log(this.id);
        this.puxaclientes();
      }
    });
  }
  goplay(){
    window.open("market://details?id=com.AppExdev.datasolmobile","_self")
  }
  goplays(){
    this.router.navigateByUrl('/myhomols');
  }


  detail(homol:string){
    this.service.changehomolfilter(homol);
    this.router.navigateByUrl('/detailview');
  }

  puxaclientes() {
    this.service.myclientes({ ID: this.id }).subscribe((data: any[]) => {
      if (data) {
        this.todosclientes = data;
        console.log('todos clientes',this.todosclientes);
      }
    });
  }

}
