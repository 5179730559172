import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, Message } from 'primeng/api';
import { ProemanagerService } from '../proemanager.service';
import { FileUploader } from 'ng2-file-upload';
import { Dropbox } from 'dropbox';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import {PrimeIcons} from 'primeng/api';
import { FILTROHOMOL } from '../delcare/dadoshomol';
import { DbxAuth } from '../delcare/configs';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import { FileManagerComponent, NavigationPaneService, ToolbarService, DetailsViewService, ContextMenuService, ToolbarCreateEventArgs } from '@syncfusion/ej2-angular-filemanager';
import * as $AB from 'jquery';
import * as $ from 'jquery';

@Component({
  selector: 'app-detailview',
  templateUrl: './detailview.component.html',
  styleUrls: ['./detailview.component.css'],
  providers: [ NavigationPaneService, ToolbarService, DetailsViewService, ContextMenuService]

})
export class DetailviewComponent implements OnInit {
  events1: any[] = [];
  events2: any[] = [];
  public navigationPaneSettings: object;

   // Mapping Tab items Header property   
   public headerText: Object = [{ text: 'Dados',  'iconCss': 'fa fa-twitter'  }, { text: 'Status',  'iconCss': 'fa fa-twitter'  }, { text: 'Arquivos',  'iconCss': 'fa fa-twitter'   }, { text: '' ,  'iconCss': 'fa fa-twitter'  }];
  acesstok:any;
  dbcAutho = [];
  autorizado = 'true';
  
  items: MenuItem[];
  items2: MenuItem[];

  activeItem: MenuItem;
  filteredhomol = "";
  display ='false';
  dataini: any;
    // options
    validacao6 ={};
    validacao3 = {};
    validacaoemail = {};
    gradient: boolean = true;
    showLegend: boolean = true;
    showLabels: boolean = true;
    isDoughnut: boolean = true;
    colorScheme = {
      domain: ['#BC0C69', '#97969A', '#C7B42C', '#FFF700', '#003482']
    };
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['codhomol', 'Cliente', 'Status',  '1p','2p',  'Menu'];
  columnsToDisplay = ['codhomol', 'Cliente',  'Status',  '1p','2p',  'Menu'];
  abrehomologacao = 'false';
  filtredhomol: any;
  status1 = '';
  status2 = '';
  status3 = '';
  status4 = '';
  datacorret: any;
  dia : any;
  ano: any;
  cams = false;
  todastarefas: any[] = [];
  configurcao: any;
  inversores = [];
  homolselec: any;
  ref = '/aplicativos/homofold/';
  reload = true;
  path = '';
  pathori = '';
  selection = new SelectionModel<any>(true, []);
  todashomologacoes: any[] = [];
  mostraavisos = false;
  nopDate: any;
  id: any;
  closeResult: any;
  finalizacao = [];
  iddonox = ""
  pago: any;
  name: any;
  categoria = [];
  cor: any;
  corred = 'red';
  corblue = 'blue';
  trasacselect: any;
  data: any;
  numerohologacaoes = 0;
  categor: any;
  valor: any;
  mes: any;
  color = 'primary';
  favorecido: any;
  descricao: any;
  okpago: any;
  validacao = [];
  notificacoes = [];
  vnotificacoes = [];
  comentarios = [];
  comment:any;
  urln: any;
  meunome: any;
  dataSource: any;
  esperadados = 0;
  esperaprojeto = 0;
  esperacesso = 0;
  esperavistoria = 0;
  iden: any;
  helpcoment = ""
  helpesk: any;
  forum = false;
  ideatuliza = [];
  single: any[] = [];
  homologado= 0;
  styleproj = 'none';
  styleproj2 = 'none';
  styleproj3 = 'none';
  styleproj4 = 'none';
  styleproj5 = 'none';
  codig : any;
  categoriarecebimento = [
    'Homologação 1º Parcela',
    'Homologação 2º Parcela',
    'Homologação total',
    'Projeto Elétrico',
    'Laudo',
    'Vistoria',
    'Execução',
    'treinamento',
    'Balanço Caixa (Entrada)',
    'Outro'];
  categoriadespesa = [
    'Aluguel',
    'Luz',
    'Água',
    'Internet',
    'Telefone',
    'Outro',
    'Balanço Caixa (Saída)'];
    public ajaxSettings: object;
    public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
    public toolbarSettings: object;
    maxFileSize = 30000000000;
    hasBaseDropZoneOver: boolean =  false ;
    hasAnotherDropZoneOver: boolean = false;
    response: any;
    validaupload = 'false';
    ultprogup: any;
    enviado = false;
    naoenviado = false;
    caminhoupar = '';
    validaversao:any;
    view: any[] = [600, 150];
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  public animationsDisabled = false;
  // ACCESS_TOKEN = 'z_fOSBgdnYAAAAAAAAAB3s5UdGqdkvu-4vrIxgErF10X1ysLyIZdHnnaO6iflABM';
  ACCESS_TOKEN = 'z_fOSBgdnYAAAAAAAAAASIRN7s6CHmaJ81vF0x1PxzHzpA1rLibkGgvlmJCBIB0q';

  dbx = new Dropbox({ accessToken: this.ACCESS_TOKEN });
  // apagar depois
  wpp: any;
  messages2: Message[] | undefined;
  menu1 = true;
  menu2 = false;
  menu3 = false;
  menu4 = false;
  clienteatual: any;
  timeline = 0;
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  dias1: any;
  dias2: any;
  dias3: any;
  dias4: any;
  textnew = '';
  display1= false;
  display2 = false;
  display3 = false;
  display4 = false;
  stausx : any[] = [];
  filtrado = false;
  tokenx:any;
  public fileManagerInstance: FileManagerComponent;

  public dbxAuth: DbxAuth;
  adbx: any;
  stream:any;
  @Input() caminho = '';
  diasx = []
  constructor(private service: ProemanagerService,private router: Router) {
    this.stream = new BehaviorSubject([]);
    this.filtredhomol = []
    this.items = [
      {label: 'Dados', icon: 'pi pi-fw pi-home',  command: (event) => {
        this.display1 = true;
        this.display2 = false;
        this.display3 = false
        this.display4 = false

        }},
      {label: 'Status', icon: 'pi pi-fw pi-calendar', command: (event) => {
        this.display1 = false;
        this.display2 = true;
        this.display3 = false
        this.display4 = false

        }},
      {label: 'Arquivos', icon: 'pi pi-fw pi-folder-open', command: (event) => {
        this.display1 = false;
        this.display2 = false;
        this.display4 = false
        this.toolbarSettings = { items: ['NewFolder','Custom', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection'], visible: true}
      this.ajaxSettings = {
        path2: this.homolselec,
        url: this.hostUrl + 'apm', 
        showHiddenItems: true,
        getImageUrl: this.hostUrl + 'GetImage',
        uploadUrl: this.hostUrl + 'Upload',
        downloadUrl: this.hostUrl + 'Download'
    };
    this.display3 = true

      }},
      {label: '', icon: '', command: (event) => {
      }},
  ];

  this.items2 = [
    {label: 'Chat', icon: 'pi pi-comment',  command: (event) => {
      this.display1 = true;
      this.display2 = false;
      this.display3 = false
      
      }}
];
  this.activeItem = this.items[0];
  this.events1 = [
    {status: 'Coleta Dados', st:0,  date: '15/10/2020 10:30', icon: PrimeIcons.DESKTOP, color: '#9C27B0', image: 'game-controller.jpg'},
    {status: 'Projeto', st:1, date: '15/10 /2020 14:00', icon: PrimeIcons.COPY, color: '#673AB7'},
    {status: 'Parecer', st:2,date: '15/10/2020 16:15', icon: PrimeIcons.ENVELOPE, color: '#FF9800'},
    {status: 'Vistoria', st:3, date: '16/10/2020 10:00', icon: PrimeIcons.CHECK, color: '#607D8B'}
];


this.events2 = [
    "2020", "2021", "2022", "2023"
];
}


toolbarCreate(args: ToolbarCreateEventArgs) {
  for(var i=0;i<args.items.length;i++) {
      if(args.items[i].id === this.fileManagerInstance.element.id +'_tb_custom') {
          args.items[i].prefixIcon= 'e-icons e-fe-tick';
      }
  }
}

// ENVIA NOTIFICACAO WHAT
enviouarquivo() {
  var grupo = "553892515427-1590502958";
  var fones = [
    "5538992515427",
    "5538991437790",
    "553899666526",
    "5538988271752",
  ];
  this.service
    .sendtext({
      sessionName: "sollux",
      number: grupo,
      text:
        "⚠️  O parceiro " +
        "*" +
        this.filtredhomol.solicitante +
        "*" +
        " anexou arquivos na pasta da homologação " +
        "*" +
        this.homolselec +
        " - " +
        this.clienteatual +
        "*"
    })
    .subscribe((dados) => {
      if (dados) {
      }
    });
}

enviouarquivod() {
  var grupo = "553892515427-1590502958";
  var fones = [
    "5538992515427",
    "5538991437790",
    "553899666526",
    "5538988271752",
  ];
  this.service
    .sendtext({
      sessionName: "sollux",
      number: grupo,
      text:
        "⚠️  O parceiro " +
        "*" +
        this.filtredhomol.solicitante +
        "*" +
        " deletou arquivos na pasta da homologação " +
        "*" +
        this.homolselec +
        " - " +
        this.clienteatual +
        "*",
    })
    .subscribe((dados) => {
      if (dados) {
      }
    });
}
notificaarqu() {
  this.service.swnotifica({ titulo: 'Arquivos Adicionados !!', 
  msg: "💾 O parceiro " +
  this.meunome +
  " anexou arquivos na pasta da homologação " +
  this.homolselec +
  " - " +
  this.clienteatual }).subscribe((datax: any[]) => {
    if (datax) {
    }
  });
}
fileOpen(args) {
  //You can get the double clicked file/folder details in this event argument. Check the arguments below.

  // cancel - If you want to cancel this event then, set cancel to true. Otherwise, false.
  // fileDetails - Return the currently selected item as JSON object.
  // module - Returns the name of the target module in file manager.

  //You can alse check whether the clicked item is a file or not using the fileDetails isFile property.
  if (!args.fileDetails.isFile) {
    //args.cancel = true

  }
}

toolbarClicks(args) { 
  if (args.item.text == "Custom") { 
      // Get the selected Files details. 
      //var selectedFiles = fileManager.getSelectedFiles()[0]; 
      // Get the path of FileManager component. 
      //var path = selectedFiles.filterPath + selectedFiles.name;
      //console.log(path) 
  } 
} 
notificaarqud() {
  this.service.swnotifica({ titulo: 'Arquivos Adicionados !!', 
  msg: "💾 O parceiro " +
  this.meunome +
  " Deletou arquivos da pasta da homologação " +
  this.homolselec +
  " - " +
  this.clienteatual }).subscribe((datax: any[]) => {
    if (datax) {
    }
  });
}


  OnbeforeSend (args){
    if(args.action==="Upload"){
      //this.filera = true
      this.enviouarquivo();
      //this.postaemail();
      //this.notificaarqu();
      var data = JSON.parse(args.ajaxSettings.data);  
      // Add custom parameter userid  
       data.push({"path2":this.homolselec}); 
       data.push({"myid":this.id}); 
      // Add custom parameter in ajax settings  
       args.ajaxSettings.data = JSON.stringify(data); 
    } 
    if(args.action==="delete"){
     this.enviouarquivod();
     //this.postaemaild() 
    } 
    var data = JSON.parse(args.ajaxSettings.data);  
    // Add custom parameter userid  
     data["path2"] = this.homolselec; 
     data["myid"] = this.id; 
     args.ajaxSettings.data = JSON.stringify(data); 
  
    } 
  ngOnInit(): void {
    this.ajax();
    this.navigationPaneSettings = {visible: false};
    this.tokenx =  localStorage.getItem('tokenx');
    this.service.getdbx({ token: this.tokenx })
    .subscribe((data) => {

      this.codig = data
      this.decryptData(this.codig.cod)}
      )
    this.service.currentHomol.subscribe(dados => {
      if (dados) {
        this.filteredhomol = dados;
        this.filtrahomol(this.filteredhomol);
        this.gethelpmsg()
      }
    });
    this.service.currentid.subscribe(dados => {
      if (dados) {
        this.id = dados;
      }
    });
    this.service.currentNome.subscribe(dados => {
      if (dados) {
        this.meunome = dados;
      }
    });
    
    this.toolbarSettings = { items: ['NewFolder','Custom', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection'], visible: true}
    this.ajaxSettings = {
      path2: this.homolselec,
      url: this.hostUrl + 'apm',
      showHiddenItems: true,
      getImageUrl: this.hostUrl + 'GetImage',
      uploadUrl: this.hostUrl + 'Upload',
      downloadUrl: this.hostUrl + 'Download'
  };
  }


  ajax(){
    this.ajaxSettings = {
      path2: this.homolselec,
      url: this.hostUrl + 'apm',
      showHiddenItems: true,
      getImageUrl: this.hostUrl + 'GetImage',
      uploadUrl: this.hostUrl + 'Upload',
      downloadUrl: this.hostUrl + 'Download'
  };
  }

  onFileSelected(a){
  
  }
  autoh(){
    this.acesstok =  new Dropbox({ accessToken: this.adbx.accessToken });
        // Begin authentication process if credentials not found

      if (this.autorizado === 'true') {
            const authUrl = this.router.url;
            const parameters = authUrl.split('#')[1] || '';
            if (this.autorizado === 'true') {
                const arrParams = parameters.split('&') || [];
                if (this.autorizado === 'true') {
                    const authObj: DbxAuth = { isAuth: false };
                    // tslint:disable-next-line: prefer-for-of
                    authObj.accessToken = this.adbx[0].accessToken;
                    authObj.tokenType = this.adbx[0].tokenType;
                    authObj.uid = this.adbx[0].uid;
                    authObj.accountId = this.adbx[0].accountId;
                    if (authObj.accessToken && authObj.tokenType && authObj.uid && authObj.accountId) {
                        authObj.isAuth = true;
                        this.dbxAuth = authObj;
                    }
                  }
            }

            this.service.changedauth(this.dbxAuth)
            // Store credentials into Auth-service and into localStorage
            // if (this.dbxAuth.isAuth) {
             //    this.authService.storeAuth(this.dbxAuth);
             //    this.podeupar = true;
          //   }
        // } else {
        }
  }

  nova(a){

  }
  decryptData(data:any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.tokenx);
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        this.adbx = dadox;
        this.autoh();
      }
      return data;
    } catch (e) {

    }
  }
  getFiles(path:any) {

    if (path === '/') {
        path = '';
    }
    this.dbx.filesListFolder({ path: decodeURI(path) })
        .then(response => {
            const entries = response.entries;
            this.stream.next(entries);
        });

}

 parseDate(dateStr) {
  if (dateStr.length === 8) {
    // Formato 01022022
    const d = parseFloat(dateStr.substring(0, 2));
    const m = parseFloat(dateStr.substring(2, 4)) - 1;
    const an = parseFloat(dateStr.substring(4, 8));
    return new Date(an, m, d);
  } else if (dateStr.length === 10) {
    // Formato 01/02/2022
    const parts = dateStr.split('/');
    const d = parseFloat(parts[0]);
    const m = parseFloat(parts[1]) - 1;
    const an = parseFloat(parts[2]);
    return new Date(an, m, d);
  } else {

    return null;
  }
}


  filtrahomol(cl:string) {
    this.reload = false;
    this.homolselec = cl;
    localStorage.setItem('homolselecionada', this.homolselec);
    this.configurcao = [];
    this.status1 = '0';
    this.status2 = '0';
    this.status3 = '0';
    this.status4 = '0';
    this.path = this.ref + cl ;
    this.pathori = this.path;
    this.caminhoupar = cl;
    this.reload = true;
    this.service.filtrahomologa({ ID: cl })
      .subscribe((data) => {

        if (data !== this.filtredhomol) {
          this.filtredhomol = data;
          this.messages2 = [
            { severity: 'warn', summary: '', detail: this.filtredhomol.pend},
        ];

          localStorage.setItem('clienteatual', data[`nome`]);
          this.stausx.push(this.filtredhomol.statusdados)
          this.stausx.push(this.filtredhomol.statusprojeto)
          this.stausx.push(this.filtredhomol.statushomologacao)
          this.stausx.push(this.filtredhomol.statusvistoria)

          this.clienteatual = this.filtredhomol.nome;
          if(this.filtredhomol.disjuntoraumentocarga ===null){
            this.filtredhomol.disjuntoraumentocarga = 'N/A'
          }
         

          this.configurcao.push({
            qtdplaca: this.filtredhomol.qtdplaca1,
            modeloplaca: this.filtredhomol.modeloplaca1,
            marcaplaca: this.filtredhomol.marcaplaca1,
            potplaca: this.filtredhomol.potplaca1,
            qtdinv: this.filtredhomol.qtdinv1,
            marcainv: this.filtredhomol.marcainv1,
            modeloinv: this.filtredhomol.modeloinv1,
            potinv: this.filtredhomol.potinv1
          })

          if (this.filtredhomol.qtdplaca2 !== null ) {
            this.configurcao.push({
              qtdplaca: this.filtredhomol.qtdplaca2,
              modeloplaca: this.filtredhomol.modeloplaca2,
              marcaplaca: this.filtredhomol.marcaplaca2,
              potplaca: this.filtredhomol.potplaca2,
              qtdinv: this.filtredhomol.qtdinv2,
              marcainv: this.filtredhomol.marcainv2,
              modeloinv: this.filtredhomol.modeloinv2,
              potinv: this.filtredhomol.potinv2
            })
            if (this.filtredhomol.qtdplaca3 !== null ) {
              this.configurcao.push({
                qtdplaca: this.filtredhomol.qtdplaca3,
                modeloplaca: this.filtredhomol.modeloplaca3,
                marcaplaca: this.filtredhomol.marcaplaca3,
                potplaca: this.filtredhomol.potplaca3,
                qtdinv: this.filtredhomol.qtdinv3,
                marcainv: this.filtredhomol.marcainv3,
                modeloinv: this.filtredhomol.modeloinv3,
                potinv: this.filtredhomol.potinv3
              })
            }
            if (this.filtredhomol.qtdplaca4 !== null ) {
              this.configurcao.push({
                qtdplaca: this.filtredhomol.qtdplaca4,
                modeloplaca: this.filtredhomol.modeloplaca4,
                marcaplaca: this.filtredhomol.marcaplaca4,
                potplaca: this.filtredhomol.potplaca4,
                qtdinv: this.filtredhomol.qtdinv4,
                marcainv: this.filtredhomol.marcainv4,
                modeloinv: this.filtredhomol.modeloinv4,
                potinv: this.filtredhomol.potinv4
              })
            }
            if (this.filtredhomol.qtdplaca5 !== null ) {
              this.configurcao.push({
                qtdplaca: this.filtredhomol.qtdplaca5,
                modeloplaca: this.filtredhomol.modeloplaca5,
                marcaplaca: this.filtredhomol.marcaplaca5,
                potplaca: this.filtredhomol.potplaca5,
                qtdinv: this.filtredhomol.qtdinv5,
                marcainv: this.filtredhomol.marcainv5,
                modeloinv: this.filtredhomol.modeloinv5,
                potinv: this.filtredhomol.potinv5
              })
            }
          }

          if (this.abrehomologacao === 'true') { this.abrehomologacao = 'false'; } else { this.abrehomologacao = 'true'; }
        }
      });
    this.filtrado = true;
    this.display1 = true;
  }
  calculateDiff(xdatax:any, dataf:any){
    let datacorreta = (xdatax).split('/');
    let currentDate = dataf
    let d = parseFloat(datacorreta[0]);
    let m = parseFloat(datacorreta[1]);
    let an  = parseFloat(datacorreta[2]);
    var datanova = new Date(an, m - 1, d);
    var diff =((currentDate.getTime() - datanova.getTime()));
    var days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return(days)
  }
  
  gethelpmsg() {
    this.forum = true;
    this.service.gethelpmsg({
    ID: this.filteredhomol })
      .subscribe(dados => {
        if (dados) {
        this.helpesk = dados
     
        }
      });

  }


}

