import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {  icon, latLng, marker, polyline, tileLayer, LeafletEvent } from 'leaflet';
import * as L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import * as $ from 'jquery';
declare var jQuery: any;
import 'esri-leaflet';
import * as html2canvas from 'html2canvas';
import * as esri from 'esri-leaflet';
import * as esriGeo from 'esri-leaflet-geocoder';
declare const screenshots: any;
declare var jQuery: any;
declare const as: any;
import 'leaflet-easyprint';
import 'leaflet-draw';
import { Router } from '@angular/router';
import { ProemanagerService } from '../proemanager.service';
import { NgxCaptureService } from "ngx-capture";
import { tap } from 'rxjs/operators';
import * as leafletImage from 'leaflet-image';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit {
  img = "";
  @ViewChild("screen", { static: true }) screen: any;

  constructor(
    private captureService: NgxCaptureService,
    private service: ProemanagerService,
    private router: Router) { 

    }
  private testefile: any;
  editableLayers = new L.FeatureGroup();
  displaym = true;
  MyCustomMarker: any;
  drawControl: any;
  polygon: any;
  printer: any;
  camadas = [];
  layer: any;
  displayx = false;
  provider = new OpenStreetMapProvider();
  newmaker: any;
  map: any;
  options: any;
  zoomv = 4;
  x: any;
  y: any;
  curlat = -16.968403143574736;
  curlong = -49.80102539062501;
  longitude: any;
  latitude: any;

  drawOptions = {
    draw: {
      polygon: {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#FFE800', // Color the shape will turn when intersects
          message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
        },
        shapeOptions: {
          color: '#97009c'
        }
      },
      // disable toolbar item by setting it to false
      polyline: false,
      circle: false, // Turns off this drawing tool
      rectangle: false,
      marker: false,
      },
    edit: {
      featureGroup: this.editableLayers,
      remove: false
    }
  };
  
  // Define our base layers so we can reference them multiple times
  streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });
  wMaps = tileLayer('http://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
    detectRetina: true,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  });
  googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
  });
  
  googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
      maxZoom: 20,
      subdomains:['mt0','mt1','mt2','mt3']
  });

  tileSource = L.tileLayer("https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",{
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3']
});
 
  // Marker for the top of Mt. Ranier
  showlayer = false;
  keylat: any;
  keylong: any;
  myObj = { lat: '', lng: '' };
  
  show = false;
  
  // Layers control object with our two base layers and the three overlay layers
  layersControl = {
    Ruas: this.streetMaps,
    Wikimedia_Maps : this.wMaps,
    Hibrido : this.googleHybrid,
    Satélite : this.googleSat,
    tileSource: this.tileSource
    };
  
    //@ViewChild('screen', {static: false}) screen: ElementRef;
    @ViewChild('canvas', {static: false}) canvas: ElementRef;
    @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;
  
  
    ngOnInit() {
 

       //this.curlat = -9.618471480912193;
       //this.curlong = -53.52539062500001;

       this.map =  L.map('map').setView([this.curlat, this.curlong], 5);
       this.drawControl = new L.Control.Draw(this.drawOptions);
       this.map.addLayer(this.editableLayers);
  
       this.map.on(L.Draw.Event.CREATED, function(e) {
        const type = e.layerType;
        const layers = e.layer;
        if (type === 'marker') {
            layers.bindPopup('A popup!');
        }
        this.editableLayers.addLayer(layers);
    });
       this.geocodificacao();
       this.show = true;
      // tslint:disable-next-line: no-unused-expression
  
    }
  
    drawmap() {
  }
  
  voltahomol(){
    //this.service.changeBase64(localStorage.getItem('basempa64'))
    this.continua()
  }
  
  
    printtela() {
      var a3Size = {
        width: 900,
        height: 1080,
        className: 'a3CssClass',
        tooltip: 'A custom A3 size'
      }
      this.printer = L.easyPrint({
      sizeModes: [ a3Size = {
        width: 900,
        height: 1080,
        className: 'a3CssClass',
        tooltip: 'A custom A3 size'
      }],
      filename: 'Localização_da_usina',
      exportOnly: true,
      hideClasses: ['leaflet-control-easyPrint'],
      hideControlContainer: false
    }).addTo(this.map);
      this.manualPrint();
      
  }
  
  manualPrint() {
    console.log(this.printer.printMap('a3CssClass', 'Localização_usina'))  
  }
  
  
  redefinir() {
    this.map.remove();
    this.map =  L.map('map').setView([this.curlat, this.curlong], 6);
    this.geocodificacao();
  }
    geocodificacao() {
      //const searchControl = new esriGeo.Geosearch().addTo(this.map);

  
      const provider = new OpenStreetMapProvider();
      const searchControl =  GeoSearchControl({
          provider: provider,
          autoComplete: true,
          showMarker: false // optional: true|false  - default true
        }).addTo(this.map);
        this.map.addControl(searchControl);
      this.camadas.push(searchControl);
      L.control.layers(this.layersControl).addTo(this.map);
      this.googleHybrid.addTo(this.map);
      const results = new L.LayerGroup().addTo(this.map);
      // tslint:disable-next-line: only-arrow-functions
      this.map.on('click', function(e) {
        if (this.newmaker !== undefined) {
          results.removeLayer(this.newmaker);};
          console.log(e)
        this.latitude = e['latlng']['lat'];
        this.longitude = e['latlng']['lng'];
        console.log(this.latitude)
        console.log(this.longitude)
        this.newmaker = L.marker([ this.latitude , this.longitude ], {
         icon: icon({
           iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 41 ],
            iconUrl: 'assets/img/marker-icon.png',
            shadowUrl: 'assets/img/marker-shadow.png'
          })
        });
        this.myObj = { lat: e['latlng']['lat'] , lng: e['latlng']['lng'] };
        localStorage.setItem(this.keylat, JSON.stringify(this.myObj));
        this.newmaker.addTo(results).bindPopup('Latitude: ' + this.latitude + '</br>' + 'Longitude:' + this.longitude );
  
      });

      }
  



        continua() {
          this.x = JSON.parse(localStorage.getItem(this.keylat))['lat']
          console.log(JSON.parse(localStorage.getItem(this.keylat)))
          this.y = JSON.parse(localStorage.getItem(this.keylat))['lng']
          console.log(this.x)
          console.log(this.y)
          this.service.mudalatitude(this.x.toString());
          this.service.mudalogitude(this.y.toString());
          this.router.navigateByUrl('/newhomol');
        }

        print(){
          var mapx = this.map
          var servico = this.service
          leafletImage(mapx, function(err, canvas) {
            // now you have canvas
            // example thing to do with that canvas:
            var img = document.createElement('img');
            var dimensions = mapx.getSize();
            img.width = dimensions.x;
            img.height = dimensions.y;
            img.src = canvas.toDataURL();
            //localStorage.setItem(img.src, 'basempa64');
            servico.changeBase64(img.src)
          });
        }
  
    }