import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Dropbox } from 'dropbox';
import * as $AB from 'jquery';
import * as $ from 'jquery';
import { AuthService } from '../auth.service';
import { FilesService } from '../files.service';
import { StorageService } from '../storage.service';
import { DbxAuth } from '../configs';
import { LocalStorageMethods, UrlMethods } from '../utils';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageData,BaseImage, DisplayConfig, SameSizeConfig, ImageEffect } from '@creativeacer/ngx-image-display';
import { ProemanagerService } from '../proemanager.service';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'ng2-file-upload';
import { MenuItem } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { FileSaverService } from 'ngx-filesaver';
import {Downloader, DownloadRequest,NotificationVisibility} from '@ionic-native/downloader/ngx'
import { CombineLatestSubscriber } from 'rxjs/internal/observable/combineLatest';
import { ControlContainer } from '@angular/forms';

declare const download: any;
declare const salvablob: any;
declare const downlodex: any;




enum hoverEffect {
    zoom = 'zoom',
    lighten = 'lighten',
    darken = 'darken',
    greyscale = 'greyscale',
    sepia = 'sepia'
  }

@Component({
    selector: 'app-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.css'],
    providers: [
      ]

})
export class StorageComponent implements OnInit, OnDestroy {
    imagesub: Subscription;
    images: Array<ImageData> = [];
    imagesx: ImageData;
    samesizeConfig: SameSizeConfig;
    displayconfig: DisplayConfig;
    hovereffect: ImageEffect;
    samesizeoption: boolean;
    fullscreenoption: boolean;
    new_array = [];
    hoverEffect: string = '';
    display = false;
    displayok = false;
    faFolderOpen = faFolderOpen
      canvasWidth = 800;
      canvasHeight = 600;   
    private hasChanged = false; // -- new property by K --
    private currentUrl = ''; // -- new property by K --
    public path;
    public storageSpace;
    public usedSpace;
    public spacePercentage;
    private starredItems = [];
    public inEntries: Array<any> = [];
    public lastSearch: Array<any> = []; // Modified by K
    private dbxAuth: any;
    private dbxAuthSubscription: Subscription;
    private fileStreamSubscription: Subscription;
    private compEntries: Array<any> = [];
    private dbxConnection;
    private showFavorites = false;
    private showFavoritesSubscription: Subscription;
    private showDeletes = false;
    private showDeletesSubscription: Subscription;
    clicked = false
    meunome: string;
    public showSearch = false;
    private showSearchSubscription: Subscription;
    closeResult = '';
    urloriginal = "";

    @Input() novocaminho = {
      a: '',
      b: ''
    };
    teste = ""
    @Input() pathori2 = '';
    caminhopdf = '';
    pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    dowlo:any;
    base:any;
    fileName: string;
    filePreview: string;
    zoomp = 1;
    imagem: any;
    nbase64data: any;
    caminhoshared: any;
    urlcompartilhadax:string;
    private imagebase64data: any;
    public page = 1;
    public pageLabel: string;
    ehpdf = 'true';
    ehimage = 'false';
    naorender = 'false';
    docFile: File;
    items: MenuItem[];
    nomearquivo: string;
    xbase64: string;
    voltar= false;
    displayx = false;
    basex = ''
    blobfile: any;
    uploader: FileUploader;
    enviado = false;
    hasBaseDropZoneOver: boolean;
    hasAnotherDropZoneOver: boolean;
    response: string;
    validaupload = 'false';
    ultprogup: number;
    podeupar = false;
    dbx:any;
    clienteatual: string;
    homolsect: string;
    constructor(private authService: AuthService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private service: ProemanagerService,
                private filesService: FilesService,
                private storageService: StorageService,
                private sanitizer: DomSanitizer,
                private spinner: NgxSpinnerService,
                private _FileSaverService: FileSaverService,
                private downloader: Downloader
                ) {
                    this.samesizeoption = true;
                    this.displayconfig = {
                      imageminwidth: '250px',
                      containerwidth: '100%',
                      containerheight: '100%',
                      fullScreenView: true // Set to false to hide the top right full screen option
                    };
                    this.hovereffect = {
                      hoverEffectActive: false
                    };
                    this.samesizeConfig = {
                      active: true,
                      imgContainerHeight: '250px'
                    }
                    
                    this.samesizeoption = true;                    
                    this.displayconfig = {
                      imageminwidth: '250px',
                      containerwidth: '80%',
                      containerheight: '500px',
                      fullScreenView: true // Set to false to hide the top right full screen option
                    };
                    this.hovereffect = {
                      hoverEffectActive: false
                    };
                    this.samesizeConfig = {
                      active: true,
                      imgContainerHeight: '250px'
                    }
                    this.service.currentdAuth.subscribe(dados => {
                        if (dados) {
                            this.dbxAuth = dados;;
                            this.dbx = new Dropbox({ accessToken: this.dbxAuth.accessToken });
                            }
                        });
                        this.uploader = new FileUploader({
                            url: '',
                            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
                            formatDataFunctionIsAsync: true,
                            formatDataFunction: async (item) => {
                              return new Promise((resolve, reject) => {
                                resolve({
                                  name: item._file.name,
                                  length: item._file.size,
                                  contentType: item._file.type,
                                  date: new Date()
                                });
                              });
                            }
                          });
                          this.hasBaseDropZoneOver = false;
                          this.hasAnotherDropZoneOver = false;
                          this.response = '';
                          this.uploader.response.subscribe(res => this.response = res);
                    }
      voltarq() {
          this.inEntries = []
                  console.log('novo path é' , this.novocaminho.b)
                  console.log(this.novocaminho.a);
                  this.filesService.getFiles(this.novocaminho.b);
                  this.fileStreamSubscription = this.filesService.stream
            .subscribe((entries) => {
                this.updateFileStream(entries);
                this.spinner.hide()
            });

        this.showFavoritesSubscription = this.storageService.showFavorites()
            .subscribe((status) => {
                this.showFavorites = status;
                if (status) {
                    const results = this.filesService.favaritesResults();
                    this.showCustomData(results);
                }
            });

        this.showDeletesSubscription = this.storageService.showDeletes()
            .subscribe((status) => {
                this.showDeletes = status;
                if (status) {
                    const data = this.filesService.deletedData();
                    this.showCustomData(data); // Continue code for this function here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                }
            });

        // Added by K
        this.showSearchSubscription = this.storageService.showSearch()
            .subscribe((status) => {
                this.showSearch = status;
                if (status) {
                    const results = this.filesService.searchResults();
                    this.showCustomData(results);
                }
            });
        // -- End new --
                }

                zoomplus(){
                    this.zoomp = this.zoomp + 0.1
                }
                zoominus(){
                    this.zoomp = this.zoomp - 0.1
                }  
        
                
       ngOnInit() {
        this.homolsect = localStorage.getItem('homolselecionada');
        this.clienteatual = localStorage.getItem('clienteatual');
        this.service.currentNome.subscribe(dados => {
            if (dados !== this.meunome && dados !== '') {
              this.meunome = dados; 
            }
          });
        this.spinner.show()
        this.items = [
            {label: 'Anexar', icon: 'pi pi-refresh', command: () => {
                this.uploadalgo();
            }},
            {label: 'Deletar todos', icon: 'pi pi-times', command: () => {
                this.uploader.clearQueue();
            
            }}
        ];

        this.dbxConnection = new Dropbox({ accessToken: this.dbxAuth.accessToken });
        this.currentUrl = UrlMethods.decodeWithoutParams(this.novocaminho.a);
        this.urloriginal = this.currentUrl;
        this.filesService.getFiles(this.currentUrl);
        this.fileStreamSubscription = this.filesService.stream
            .subscribe((entries) => {
                this.updateFileStream(entries);
                this.spinner.hide()
            });

        this.showFavoritesSubscription = this.storageService.showFavorites()
            .subscribe((status) => {
                this.showFavorites = status;
                if (status) {
                    const results = this.filesService.favaritesResults();
                    this.showCustomData(results);
                }
            });

        this.showDeletesSubscription = this.storageService.showDeletes()
            .subscribe((status) => {
                this.showDeletes = status;
                if (status) {
                    const data = this.filesService.deletedData();
                    this.showCustomData(data); // Continue code for this function here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                }
            });

        // Added by K
        this.showSearchSubscription = this.storageService.showSearch()
            .subscribe((status) => {
                this.showSearch = status;
                if (status) {
                    const results = this.filesService.searchResults();
                    this.showCustomData(results);
                }
            });
        // -- End new --

    }

    // New method to auto rerender this component
    checkHasChanged() {
        if (this.hasChanged) {
            this.filesService.getFiles(this.currentUrl);
        } else {
        }
    }
    // -- End new --

    updateFileStream(inData: Array<any>) {
        if (!this.showFavorites && !this.showSearch && !this.showDeletes) {
            this.compEntries = inData;
            this.getData();
            this.renderData(this.compEntries);
        }
    }

    showCustomData(inData: Array<any>) {
        if (this.showFavorites) {
            this.renderData(inData);
            this.storageService.deactivateShowFavorites();
        } else if (this.showSearch) {
            this.renderData(inData);
            this.storageService.deactivateShowSearch();
        } else if (this.showDeletes) {
            // code to render data here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
            const objDeleteArr: Array<any> = [];
            inData.forEach(name => {
                objDeleteArr.push({
                    name: name,
                    path_lower: ''
                });
            });
            this.renderData(objDeleteArr);
            this.storageService.deactivateShowDeletes();
        }
    }

    getData() {
        this.dbxConnection
            .usersGetSpaceUsage(null)
            .then(spaceInfo => {
                this.storageSpace = (spaceInfo.allocation.allocated / 1024 / 1024 / 1024).toFixed(2);
                this.usedSpace = (spaceInfo.used / 1024 / 1024 / 1024).toFixed(2);
                this.spacePercentage = (this.usedSpace / this.storageSpace) * 100;
            })
            .catch(error => {
                console.log(error);
            });
    }



    downloadFile(filepath, filename, event) {
        this.spinner.show();
        event.preventDefault();
        this.dbxConnection
            .filesDownload({ path: filepath })
            .then(data => {
                console.log(filepath)
                this.caminhoshared = filepath;
                const fileurl = URL.createObjectURL((<any>data).fileBlob);
                const a = document.createElement('a');
                if (this.isImage(data.path_lower)) {
                   this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                   this.nomearquivo = filename;
                   this.blobfile = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                   this.ehpdf = 'false';
                   this.naorender = 'false'
                   this.downlo();
                   a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                //this.downlo()
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    //var base64data = reader.result;
                    var base64data = (<string>reader.result).split(',')[1];
                    this.xbase64 = base64data;
                    this.imagebase64data = base64data
                    this.basex = 'data:image/png;base64,' + this.imagebase64data
                    this.images = [
                        {
                          type: 'base64',
                          imageData: {
                            value: 'data:image/png;base64,' + this.imagebase64data
                          }
                        }
                      ];
           
                    this.spinner.hide();
                    const imageName = filename;
                    const imageBlob = this.dataURItoBlob(this.imagebase64data);
                    console.log(imageBlob)
                    this.docFile = new File([imageBlob], imageName, { type: 'image/png' });
                    this.ehimage = 'true';
                    this.viewimg();
                }
                }
                if (this.Ispdf(data.path_lower))  {
                this.ehimage = 'false';
                this.naorender = 'false'
                this.ehpdf = 'true';
                a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                //this.downlo()
                this.spinner.hide();
                this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                this.nomearquivo = filename;
                this.blobfile = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                var reader = new FileReader();
                this.downlo()
                reader.readAsDataURL(this.imagem); 
                reader.onloadend = (e)  => {
                    var base64data = (<string>reader.result).split(',')[1];
                    this.nbase64data = base64data
                }}
                if (!this.Ispdf(data.path_lower) && !this.isImage(data.path_lower) ) {
                    this.naorender = 'true'
                    this.ehimage = 'false';
                    this.ehpdf = 'false';
                    this.imagem = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                    this.nomearquivo = filename;
                    this.blobfile = new Blob([data.fileBlob], { type: 'application/octet-stream' });
                    console.log(this.imagem)
                    a.setAttribute('href', fileurl);
                a.setAttribute('download', filename);
                this.caminhopdf =  a.href;
                this.dowlo = a
                this.downlo();

                //this.downlo()
            }
                                //a.click();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onSave() {
        //downlodex(this.urlcompartilhadax)
        var dowloadurl = this.urlcompartilhadax;
        var request: DownloadRequest = {
            uri: dowloadurl,
            title: "datasolmobile",
            description: "Downlad arquivo cotação",
            visibleInDownloadsUi: true,
            notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
            destinationInExternalFilesDir: {
                    dirType: 'Downloads',
                    subPath:''
            }
        };
        this.downloader.download(request).then((location: string)=>{
            alert("Arquivo sendo baixado em  " + location)
        }, 
        (err)=>{console.log(JSON.stringify(err))});
      }
//UPLOAD
    filesect(a){
        this.uploadalgo()
        }

        async uploadalgo() {
            this.enviouarquivo();
            for (let i = 0; i < this.uploader.queue.length; i++) {
              await this.asyncUpload(i);
            }
          }
          clicabotao(){
            let element: HTMLElement = document.getElementById('file-input') as HTMLElement;
            element.click(); 
           }
        asyncUpload(i) {
            return new Promise((resolve, reject) => {
              // tslint:disable-next-line: max-line-length
              this.dbx.filesUpload({ path: this.currentUrl + "/" + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[0]['_file'] })
                // tslint:disable-next-line: only-arrow-functions
                .then(response => {
                  this.enviado = true;
                  this.uploader.queue[0]['isUploaded'] = true;
                  this.uploader.queue[0]['isSuccess'] = true;
                })
                .catch(error => reject())
                .finally(() => 
                this.displayok = true,
                this.filesService.getFiles(this.currentUrl)
                );
            });
          }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });    
        return blob;
     }
      

    waitForOneSecond() {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve("I promise to return after one second!");
          }, 1000);
        });
      }
      downlo(){
       var salva = salvablob(this.blobfile, this.nomearquivo)
        console.log(this.caminhoshared)
        if (salva = true){
        this.spinner.hide()
        this.display = true;
        }
        //this.dowlo.click()
      }
      mostraevento(a) {
        console.log(a)
        this.clicked = true;
        if(this.currentUrl ===this.urloriginal ){
            console.log(a)
        }
        if (a === 'fotos_vistoria'){
            this.clicked = true;
          this.filesService.getFiles(this.currentUrl + '/fotos_vistoria' );
          setTimeout(() => {
            console.log('renderizou')
          }, 1000);
        }
        if (a === 'Documentos_tecnicos'){
            this.clicked = true;
            this.filesService.getFiles(this.currentUrl + '/Documentos_tecnicos' );
            setTimeout(() => {
              console.log('renderizou')
            }, 1000);
          }

          if (a === "....."){
            this.voltarq()
          }
          
          this.fileStreamSubscription = this.filesService.stream
              .subscribe((entries) => {
                  this.updateFileStream(entries);
              });
  
          this.showFavoritesSubscription = this.storageService.showFavorites()
              .subscribe((status) => {
                  this.showFavorites = status;
                  if (status) {
                      const results = this.filesService.favaritesResults();
                      this.showCustomData(results);
                  }
              });
  
          this.showDeletesSubscription = this.storageService.showDeletes()
              .subscribe((status) => {
                  this.showDeletes = status;
                  if (status) {
                      const data = this.filesService.deletedData();
                      this.showCustomData(data); // Continue code for this function here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                  }
              });
  
          // Added by K
          this.showSearchSubscription = this.storageService.showSearch()
              .subscribe((status) => {
                  this.showSearch = status;
                  if (status) {
                      const results = this.filesService.searchResults();
                      this.showCustomData(results);
                  }
              });
              this.voltar = true;
          // -- End new --
      }


      voltars() {
        this.filesService.getFiles(this.currentUrl );
          this.fileStreamSubscription = this.filesService.stream
              .subscribe((entries) => {
                  this.updateFileStream(entries);
              });
  
          this.showFavoritesSubscription = this.storageService.showFavorites()
              .subscribe((status) => {
                  this.showFavorites = status;
                  if (status) {
                      const results = this.filesService.favaritesResults();
                      this.showCustomData(results);
                  }
              });
  
          this.showDeletesSubscription = this.storageService.showDeletes()
              .subscribe((status) => {
                  this.showDeletes = status;
                  if (status) {
                      const data = this.filesService.deletedData();
                      this.showCustomData(data); // Continue code for this function here<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                  }
              });
  
          // Added by K
          this.showSearchSubscription = this.storageService.showSearch()
              .subscribe((status) => {
                  this.showSearch = status;
                  if (status) {
                      const results = this.filesService.searchResults();
                      this.showCustomData(results);
                  }
              });
              this.voltar = false;
          // -- End new --
      }
    // Get latest search results -- Added by K
    retrieveLatestSearch() {
        const numberOfSearchResults = 3; // You decide yourself how many you want
        let searchResults = this.filesService.searchResults();
        const arrLength = searchResults.length;
        if (arrLength > 0) {
            if (arrLength > numberOfSearchResults) {
                searchResults = searchResults.slice(0, numberOfSearchResults);
            }
            this.lastSearch = searchResults;
        }
    }

    renderData(inEntries: Array<any>) {
        /* Run HERE this method to retrieve exactly the latest search results
           before you render data */
        this.retrieveLatestSearch();

        // You can use the latest results by using this.lastSearch

        if (inEntries.length > 0) {
            if (LocalStorageMethods.retrieve('entries') !== null) {
                for (let i = 0; i < inEntries.length; i++) {
                    inEntries[i].starred = checkStars(inEntries[i]);
                }
            } else {
                for (const entry of inEntries) {
                    entry.starred = false;
                }
            }
        }
        this.inEntries = inEntries;
        console.log(this.inEntries)
    }



    isImage(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const supportedImages = ['jpg', 'jpeg', 'JPEG', 'PNG', 'png', 'tiff', 'tif', 'gif', 'bmp'];
            const fileEnding = fileName.toLowerCase().split('.').pop();
            if (supportedImages.some(imgType => imgType === fileEnding)) {
                return true;
            }
        }
        else{
            return false;
        }
   
    }

    Ispdf(fileName: string) {
        
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const supportedImages = ['PDF', 'pdf'];
            const fileEnding = fileName.split('.').pop();
            if (supportedImages.some(imgType => imgType === fileEnding)) {
                return true;
                
            }
        }
        return false;
    }

    getFileType(fileName: string) {
        if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
            const fileEnding = fileName.split('.').pop();
            let fileType = ''; // Fixed bug by K
            switch (fileEnding) {
                case 'pdf':
                    fileType = 'fas fa-file-pdf fa-2x';
                    break;
                case 'docx' || 'docx':
                    fileType = 'fas fa-file-word fa-2x';
                    break;
                case 'pptx':
                    fileType = 'fas fa-file-powerpoint fa-2x';
                    break;
                case 'xlsx':
                    fileType = 'fas fa-file-excel fa-2x';
                    break;
                case 'html' || 'js':
                    fileType = 'fas fa-file-code fa-2x';
                    break;
                default:
                    fileType = 'fa fa-file fa-2x';
            }
            return fileType;
        }
        return false;
    }

    addStar(id, event) {
        event.preventDefault();
        this.starredItems = LocalStorageMethods.retrieve('entries') || [];
        const foundItem = this.compEntries.find(item => item.id === id) || {};
        if (foundItem) {
            foundItem.starred = true;
            this.starredItems.push(foundItem);
            LocalStorageMethods.store('entries', this.starredItems);
        }
    }

    enviouarquivo(){
        var grupo = '553892515427-1612924425'
        var fones = ['5538992515427','5538991437790','553899666526','5538988271752']
        //for (let i = 0; i < fones.length; i++) {
        this.service.sendtext({
          sessionName: 'sollux',
          number: grupo,
          text: '⚠️  O parceiro ' + '*' +this.meunome +'*'+ ' anexou arquivos na pasta da homologação ' + '*'+ this.homolsect + ' - '+ this.clienteatual + '*'
         })
          .subscribe(dados => {
            if (dados) {
              console.log(dados);
            }
          });
          // }
    }

    delStar(id, event) {
        event.preventDefault();

        // Clear item in this.inEntries
        const foundItem = this.inEntries.find(item => item.id === id);
        if (foundItem) { // Fixed bug by K
            foundItem.starred = false;
        }

        // Clear item in localStorage
        this.starredItems = LocalStorageMethods.retrieve('entries') || [];
        this.starredItems = this.starredItems.filter(el => el.id !== id);

        // Store back to localStorage
        LocalStorageMethods.store('entries', this.starredItems);
    }

    ngOnDestroy() {

    }
    viewimg(){
        this.displayx = true;
    }
}



function checkStars(inItem: any) {
    const currentStartItems = LocalStorageMethods.retrieve('entries') || [];
    const results = currentStartItems.filter(item => item.id === inItem.id) || [];

    return results.length > 0 ? true : false;
}
