import { Component, OnInit } from '@angular/core';
import { ProemanagerService } from '../proemanager.service';

@Component({
  selector: 'app-myac',
  templateUrl: './myac.component.html',
  styleUrls: ['./myac.component.css']
})
export class MyacComponent implements OnInit {
  dadosolicitante = [];
  id: string;
  resourcesLoaded = '';
  nome = '';
  cpf ='';
  rua = '';
  bairro = '';
  numero = '';
  cidade = '';
  estado = '';
  cep = '';
  email = '';
  telefone = '';
  validacao = [];
  senha1 = '1';
  senha2= '2';
  url= '';
  complemento = '';
  valid={};
  sp= true;
  constructor(private service: ProemanagerService) { }

  ngOnInit(): void {
    this.service.currentid.subscribe(dados => {
      if (dados !== this.id && dados !== '' ) {
        this.resourcesLoaded = dados;
        this.id = dados;
        console.log(this.id)
        this.puxasolicitante(); }  } ); 

  }
  puxasolicitante(){
    this.service.dadossolicitante({ID: this.id })
    .subscribe(dados => {
      if (dados !== this.dadosolicitante) {
        this.dadosolicitante = dados;
        this.resourcesLoaded = 'false';
        this.atualizanomes();

      }
      })}

atualizanomes(){
  this.nome = this.dadosolicitante['nomeparceiro1'];
  this.cpf = this.dadosolicitante['cnpjparceiro1'];
  this.rua = this.dadosolicitante['ruaparceiro'];
  this.bairro = this.dadosolicitante['bairroparceiro'];
  this.numero = this.dadosolicitante['numruaparceiro'];
  this.cidade = this.dadosolicitante['cidadeparceiro'];
  this.estado = this.dadosolicitante['ufparceiro'];
  this.cep = this.dadosolicitante['cepparceiro'];
  this.email = this.dadosolicitante['emailparceiro'];
  this.telefone = this.dadosolicitante['celparceiro'];
  this.complemento = this.dadosolicitante['complemento'];
  this.url = this.dadosolicitante['pic']
  this.sp = false;
  this.service.changeurl(this.url)
}


upacliente() {
  this.service.updateparceiro2({
    token:localStorage.getItem('tokenx'),
    nome: this.nome,
    cnpj: this.cpf,
    endereco: this.rua,
    cidade: this.cidade,
    uf: this.estado,
    bairro: this.bairro,
    complemento: this.complemento,
    cep: this.cep,
    idempresa: this.id,
    numruaparceiro: this.numero,
    email: this.email,
    celparceiro: this.telefone
  })
    .subscribe((data) => {
      if (data['status'] === 'atualizado') {
        this.valid = data;
        this.puxasolicitante();
      }
    });
}

upasenha() {
  this.service.upasenha({
    token:localStorage.getItem('tokenx'),
    senha: this.senha2,
    idempresa: this.id,
  })
    .subscribe((data) => {
      if (data['status'] === 'atualizado') {
        this.valid = data;
        console.log(this.valid);
        this.puxasolicitante();
      }
    });
}

}
