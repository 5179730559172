import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { tap, delay, take, map, catchError } from 'rxjs/operators';
import { ID } from './delcare/id';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FILTROHOMOL } from './delcare/dadoshomol';
import { DbxAuth } from './configs';
import { todosdados } from './delcare/todosdados';
import { PAINV } from './delcare/parametrosinv';
import { MOD } from './delcare/modelomodu';
import { PAMOD } from './delcare/parametrosmod';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})




export class ProemanagerService {
  fs: any;

  dbex: DbxAuth;

  public getToken() {
    return localStorage.getItem('tokenx');
  }
  constructor(private http: HttpClient, private router: Router) {
    this.fs = (window as any).fs;
      // Inicializa o BehaviorSubject com o estado inicial de não autenticado
      this.autenticadoSubject = new BehaviorSubject<boolean>(false);
   }

   
// tslint:disable-next-line: max-line-length
private authSource = new BehaviorSubject('false');
currentauth = this.authSource.asObservable();

private autenticadoSubject: BehaviorSubject<boolean>;



  getAutenticadoObservable(): Observable<boolean> {
    // Fornece uma Observable para outros componentes se inscreverem
    return this.autenticadoSubject.asObservable();
  }

  realizarLogin() {
    // Lógica para autenticação bem-sucedida
    this.atualizarEstadoAutenticacao(true);
  }

  realizarLogout() {
    // Lógica para logout
    this.atualizarEstadoAutenticacao(false);
  }

  isAutenticado(): boolean {
    // Obtém o valor atual do BehaviorSubject
    return this.autenticadoSubject.getValue();
  }

  private atualizarEstadoAutenticacao(novoEstado: boolean) {
    // Atualiza o estado de autenticação e notifica os inscritos
    this.autenticadoSubject.next(novoEstado);
  }

// tslint:disable-next-line: max-line-length
private carteiraSource = new BehaviorSubject(0);
currentCarteira = this.carteiraSource.asObservable();

private latitudesource = new BehaviorSubject(null);
currentelatitude = this.latitudesource.asObservable();
private longitudesource = new BehaviorSubject(null);
currentelongitude = this.longitudesource.asObservable();

private formSource = new BehaviorSubject([]);
currentForm = this.formSource.asObservable();

private dauthSource = new BehaviorSubject([]);
currentdAuth = this.dauthSource.asObservable();

private abriumapaSource = new BehaviorSubject('false');
currentabriumapa = this.abriumapaSource.asObservable();


private nomeSource = new BehaviorSubject('false');
currentNome = this.nomeSource.asObservable();

private idSource = new BehaviorSubject('false');
currentid = this.idSource.asObservable();

private wppSource = new BehaviorSubject('false');
currentwpp = this.wppSource.asObservable();

private urlSource = new BehaviorSubject('false');
currenturl = this.urlSource.asObservable();

private validosource = new BehaviorSubject('0');
currentvalido = this.validosource.asObservable();

private homolSource = new BehaviorSubject('0');
currentHomol = this.homolSource.asObservable();


private Bae64Source = new BehaviorSubject('0');
currentBae64Source = this.Bae64Source.asObservable();

// APIS LOCAL

changeBase64(img: any) {
  this.Bae64Source.next(img);
}

mudaform(formular: any) {
  this.formSource.next(formular);
}

changedauth(dauth) {
  this.dauthSource.next(dauth);
}

public getJSON(): Observable<any> {
  return this.http.get('/storage/emulated/0/datamob.json');
}

changemapa(maps: string) {
  this.abriumapaSource.next(maps);
}
// read file synchronous
getFile(path: string) {
// return synchronous filestream
return this.fs.readFileSync(path);
}
gethelpmsg(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/gethelpmsg', {params : paramns2})
.pipe(tap());
}
// API PUXA CLIENTES
myclientes(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/myclientes', {params : paramns2 ,headers: headers})
.pipe(tap());
}

validalogin(valido: string) {
this.validosource.next(valido);
}

mudalogitude(longitude: string) {
  this.longitudesource.next(longitude);
}
mudalatitude(latitude: string) {
  this.latitudesource.next(latitude);
}

public getradiacao(): Observable<any> {
  return this.http.get('./assets/json/radiacao.json');
}
// API PUXA indicador
swnotifica(paramns2) {
  //const headers= new HttpHeaders()
  //.set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/swnotificatodos', {params : paramns2})
.pipe(tap());
}
public getradiacaoglobal(): Observable<any> {
return this.http.get('./assets/json/radiacaoglobal.json');
}

public getemperatura(): Observable<any> {
return this.http.get('./assets/json/temperaturas.json');
}



changeCarteira(nome:any) {
  this.carteiraSource.next(nome);
}

changeNome(nome: string) {
  this.nomeSource.next(nome);
}

changeauth(aut: string) {
  this.authSource.next(aut);
}

changeid(id: string) {
  this.idSource.next(id);
}
changewpp(wpp: string) {
  this.wppSource.next(wpp);
}

changehomolfilter(homol: string) {
  this.homolSource.next(homol);
}

changeurl(url: string) {
  this.urlSource.next(url);
}

  // APIS SERVIDOR
gettok(paramns1:any) {
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/login', {params: paramns1})
.pipe(tap()
    );
}

// API LOGIN NODE SERVER
loginauth(paramns1:any) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/logindados', {params : paramns1, headers: headers })
  .pipe(tap());
}

// API PUXA HOMOLOGAÇÕES
myhomols(paramns2:any) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/myhomol', {params : paramns2, headers: headers })
.pipe(tap());
}

criafolder(params3) {
  return this.http.post<any[]>('https://www.talktunnels.com/api/_functions/FileManager/criafolder', {params : params3 })
.pipe(tap());
}

// API FILTRA HOMOL
filtrahomologa(paramns2:any) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<FILTROHOMOL[]>('https://www.sollux.dev/api/_functions/consultas/filtrahomol',{params : paramns2, headers: headers })
.pipe(tap());
}

// API FILTRA HOMOL
getdbx(paramns2:any) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<FILTROHOMOL[]>('https://www.sollux.dev/api/_functions/consultas/dbox',{params : paramns2, headers: headers })
.pipe(tap());
}

 // DADOS PARCEIRO
 dadossolicitante(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/solicitante', {params : paramns2, headers: headers })
.pipe(tap());
}

// API UPDATE PARCEIRO
updateparceiro2(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/updateparceiro2', {params : params3, headers: headers })
.pipe(tap());
}

// API UPDATE senh
upasenha(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upasenha', {params : params3, headers: headers })
.pipe(tap());
}

// API UPDATE senh
upasenha2(params3) {
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upasenha2', {params : params3})
.pipe(tap());
}
// API PUXA COMENTARIO
puxachat(params3) {
  return this.http.get('https://www.sollux.dev/api/_functions/consultas/filtchatx', {params : params3})
.pipe(tap());
}

// API PUXA COMENTARIO
puxacoment(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get('https://www.sollux.dev/api/_functions/consultas/comentarios', {params : params3, headers: headers })
.pipe(tap());
}

 // API INSERE COMENTARIO
 novocomment(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  const parametro = {
    'msg':params3.msg, 
    'id':'s', 
    'idono': params3.idono, 
    'hl':params3.hl, 
    'data':params3.data, 
    'lida':'0', 
    'nome':params3.nome, 
    'urlpic': params3.urlpic,
    'token': this.getToken()
  }
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/insertcomment', {params : parametro})
.pipe(tap());
}
// API PUXA DEBITOS
Debitos(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/meusdebitos', {params : paramns2, headers: headers })
.pipe(tap());
}

 // API INSERE HOMOLOGACAO
 novahomologacao(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/inserthomol', {params : params3, headers: headers })
.pipe(tap());
}
// API INSERE TAREFAS HOMOLOGACOES
newtaskhomol(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/inserttaskhomol', {params : paramns2, headers: headers })
.pipe(tap());
}

// API eni
sendtext(params3) {
  return this.http.post<any[]>('https://www.talktunnels.com/api/_functions/whatsapp/sendText', {params : params3 })
.pipe(tap());
}

// API PUXA COMENTARIO
enviaemail(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/solux1', {params : params3, headers: headers })
.pipe(tap());
}

// API eni
sendtextuni(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post<any[]>("https://www.talktunnels.com/api/_functions/whatsapp/sendText", {params : params3 })
.pipe(tap());
}


// API  ARQUIVOS NECESSARIOS
arquivosnecessarios(paramns1) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/arquivosnecessarios', {params : paramns1, headers: headers })
.pipe(tap()
    );
}
 // API PUXA SOLICITANTE
 gsolicitante(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/solicitante', {params : params3, headers: headers })
.pipe(tap());
}

// API ARQ NEC
dowconcessionarias() {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/concessionarias', {headers: headers })
.pipe(tap()
    );
}


// API PUXA VISTORIAS
myvistoria(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<todosdados[]>('https://www.sollux.dev/api/_functions/consultas/myvisto', {params : paramns2, headers: headers })
.pipe(tap());
}
// API PUXA FILTRA VISTORIAS
filtravisto(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/filtravisto', {params : paramns2, headers: headers })
.pipe(tap());
}

// API SOLICITA VISTORIAS
solicitavisto(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/sendmail/vistoriaenviada', {params : paramns2, headers: headers })
.pipe(tap());
}

// API UPDATE STATUS VISTORIA
upastatusvisto(params3) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/upvistohomol', {params : params3, headers: headers })
.pipe(tap());
}

// PUXA puxa marca
potmod(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/marcamodulos', {params : paramns2, headers: headers })
.pipe(tap());
}

 // PUXA puxa mdoelo modulo
 modelomodu(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MOD[]>('https://www.sollux.dev/api/_functions/consultas/modelomodulos', {params : paramns2, headers: headers })
.pipe(tap());
}

// PUXA parametros modulos
parametrosmodulo(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<PAMOD[]>('https://www.sollux.dev/api/_functions/consultas/parametromodulo', {params : paramns2, headers: headers })
.pipe(tap());
}

 // PUXA POTENCIA DOS INVERSORES
 potinv(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/potenciainversores', {params : paramns2, headers: headers })
.pipe(tap());
}

 // PUXA puxa marca inversor
 marcainver(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<any[]>('https://www.sollux.dev/api/_functions/consultas/marcainversores', {params : paramns2, headers: headers })
.pipe(tap());
}

// PUXA modelo inversor
modeloinv(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<MOD[]>('https://www.sollux.dev/api/_functions/consultas/modeloinversores', {params : paramns2, headers: headers })
.pipe(tap());
}

// PUXA parametros inversores
parametrosinversores(paramns2) {
  const headers= new HttpHeaders()
  .set('Authorization', 'Bearer ' + this.getToken());
  return this.http.get<PAINV[]>('https://www.sollux.dev/api/_functions/consultas/parametrosinversores', {params : paramns2, headers: headers })
.pipe(tap());
}

// API GET VERSÃO
versao(paramns1) {
  return this.http.get<ID[]>('https://www.sollux.dev/api/_functions/consultas/versaodatamob', {params : paramns1 })
.pipe(tap()
    );
}
// API RECUPERA
recuperasenha(paramns2) {
  return this.http.get('https://www.sollux.dev/api/_functions/consultas/recuperasenha', {params : paramns2})
.pipe(tap());
}



// ProemanagerService
loginAutomatico(token: string): Observable<boolean> {
  // Retorna um Observable booleano indicando se o login automático foi bem-sucedido

  // Realiza uma solicitação HTTP ao servidor para verificar o token
  return this.http.post('https://www.sollux.dev/api/_functions/consultas/token_check', { token }).pipe(
    map((response: any) => {
      if (response && response.valid === false) {

        // Se a resposta indicar que o login automático falhou, redirecione para a página de login
        this.router.navigate(['/login']);
        return false;
      } else {
        const decodedToken  = jwt_decode(token);
        this.validalogin('true');
        this.changeid(decodedToken['ID']);
        this.changeNome(decodedToken['nome']);
        this.changeurl(decodedToken['pic']);
        this.changewpp(decodedToken['wpp']);
        this.router.navigateByUrl('/myhomols');
        // Se o login automático foi bem-sucedido ou se a resposta não puder ser avaliada, permita o acesso à rota
        return true;
      }
    }),
    catchError((error) => {
      // Lógica de tratamento de erro
      console.error(error);
      // Redireciona para a página de login
      this.router.navigate(['/login']);
      // Retorna um Observable de false para indicar que o login automático falhou
      return of(false);
    })
  );
}


// API GET TOKEN
// API UPDATE SUB
upasubnot(params3) {
  return this.http.post<any[]>('https://www.sollux.dev/api/_functions/consultas/upadatasub', {params : params3 })
.pipe(tap());
}

}
