import { Component } from '@angular/core';
import { ProemanagerService } from './proemanager.service';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFacF5JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1jWn9Xc3BVTmRbUkU=');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  validado = false;
  title = 'datasolmobile';
  validaversao:any;
  displayx = false;
  public ajaxSettings: object;
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";

  constructor(private service: ProemanagerService) { 
  }
  ngOnInit(): void {
   
    this.ajaxSettings = {
        path2: "HL1592180235",
        url: this.hostUrl + 'ap',
        showHiddenItems: true,
        getImageUrl: this.hostUrl + 'GetImage',
        uploadUrl: this.hostUrl + 'Upload',
        downloadUrl: this.hostUrl + 'Download'
    };
  
  }
}
