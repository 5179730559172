import { Component, OnInit , NgZone, Injector} from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ProemanagerService } from '../proemanager.service';
declare const crenditialssollux: any;
import * as CryptoJS from 'crypto-js';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit  {
  bytes:any
  options: AnimationOptions = {
    path: 'assets/sollux.json'
  };
  credentials: any;
  tokenization:any;
  usuario= '';
  senha= '';
  url= '';
  filelogin: any;
  idcliente= '';
  message= '';
  nome= '';
  ID:any;
  errologin = false;
  stroedjson =[]
  constructor(private service: ProemanagerService,private router: Router,
    private injector: Injector) { }


  ngOnInit(): void {
  }

  onLoopComplete(){
    this.service.getJSON().subscribe(data => {
      if (data) {
        this.stroedjson = data
        this.credentials = this.decryptx(this.stroedjson['kod'])
        this.decryptx(this.credentials)
    }
    else {    const routerService = this.injector.get(Router);
      const ngZone = this.injector.get(NgZone);
      ngZone.run(() => {
        routerService.navigate(['/login'], { replaceUrl: true, skipLocationChange: true  });
      });}
  }, error => this.vaza()
  );
  }

  vaza(){
    const routerService = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);
    ngZone.run(() => {
      routerService.navigate(['/login'], { replaceUrl: true, skipLocationChange: true  });

  })}

  vaza2(){
    const routerService = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);
    ngZone.run(() => {
      routerService.navigate(['/myhomols'], { replaceUrl: true, skipLocationChange: true  });

  })}

  decryptx(data) {
    try {
      this.bytes = CryptoJS.AES.decrypt(data, '');
      if (this.bytes.toString()) {
        var dadox = (JSON.parse(this.bytes.toString(CryptoJS.enc.Utf8)));
        this.usuario = (dadox['name']);
        this.senha = (dadox['pass']);
        this.onClick() 
      }
      return data;
    } catch (e) {
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  onClick() {
    this.service.gettok({ email: this.usuario, senha: this.senha })
      .subscribe(dados => {
        if (dados) {
          this.tokenization = dados
          localStorage.setItem('tokenx', this.tokenization['token']);
          this.autoriza()
        }
      });
  }

  autoriza(){
    const tokenx = localStorage.getItem('tokenx');
    this.service.loginauth({
      email: this.usuario, 
      senha: this.senha
    })
    .subscribe(dados => {
      if (dados) {
        //crenditialssollux({kod:this.codifica({ name: this.usuario,pass: this.senha})});
        this.ID = dados;
        this.url = this.ID.pic['pic'];
        this.nome = this.ID['nome']
        this.service.changeid(this.ID['ID']);
        this.service.changeNome(this.ID['nome']);
        this.service.changeurl(this.ID['pic']);
        this.service.changewpp(this.ID['wpp']);
        this.gomyhomol()
      }
    });
  }

  gomyhomol() {
    if (this.ID['Valido'] === 1) {
      localStorage.setItem('emaildatasol', this.usuario);
      localStorage.setItem('senhadatasol', this.senha);
      localStorage.setItem('email', this.usuario);
      localStorage.setItem('senha', this.senha);
      this.service.validalogin('true');
      this.vaza2()
      // tslint:disable-next-line: max-line-length
    } else { this.errologin = true; }
  }

}
