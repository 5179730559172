import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { ClimanagerComponent } from './climanager/climanager.component';
import { DebitosComponent } from './debitos/debitos.component';
import { DetailviewComponent } from './detailview/detailview.component';
import { DimensionadorComponent } from './dimensionador/dimensionador.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MapsComponent } from './maps/maps.component';
import { MyacComponent } from './myac/myac.component';
import { MyhomolsComponent } from './myhomols/myhomols.component';
import { NewhomolComponent } from './newhomol/newhomol.component';
import { VistoriaComponent } from './vistoria/vistoria.component';
import { GuardamobileGuard } from './guardamobile.guard';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'climanager', component: ClimanagerComponent},
  {path: 'myhomols', component: MyhomolsComponent},
  {path: 'detailview', component: DetailviewComponent},
  {path: 'minhaconta', component: MyacComponent},
  {path: 'chat', component: ChatComponent},
  {path: 'vistoria', component: VistoriaComponent},
  {path: 'debitos', component: DebitosComponent},
  {path: 'newhomol', component: NewhomolComponent},
  {path: 'maps', component: MapsComponent},
  {path: '', component: HomeComponent, canActivate: [GuardamobileGuard]},
  {path: 'dimensionador', component: DimensionadorComponent}


];
const config: ExtraOptions = {
  useHash: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
