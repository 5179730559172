import { Component, Injector, NgZone, OnInit } from '@angular/core';
import { PAINV } from '../delcare/parametrosinv';
import { PAMOD } from '../delcare/parametrosmod';
import { todosdados } from '../delcare/todosdados';
import { ProemanagerService } from '../proemanager.service';
import { Finance } from 'financejs';
import {TreeNode} from 'primeng/api';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Router } from '@angular/router';

declare const lerad: any;
declare const cidade: any;



export interface Potencia {
  value: number;
  viewValue: string;
}
export interface MODULOMODELO {
  modelo: string;
  codice2: string;
}
export interface ARRAYINVERSORES {
  arrainv: INVERCONF[];
}
export interface NUMMPPT {
  numofmppt: string;
  href: string;
  cor_max: number;
  vmp_min: number;
  vmp_max: number;
  pot_max: number;
  qtd_mod_ser: number;
  qtd_mod_par: number;
  voc: number;
  vnom: number;
  Ic: number;
  potconfigurada: number;
  pottotalconfigurada: number;
  qtd_modulos: number;
}
export interface INVERSOREMCASCATA {
  coinv: NUMMPPT[];
}
export interface IDSCONF1 {
  value: number;
  viewValue: string;
}


export interface CARTEIRA {
  usoproposta: string;
  usoprojeto: string;
  creditoprojeto: string;
  creditoproposta: string;
}

export interface INVERCONF {
  coinv: NUMMPPT[];
  potencia: number;
  rendimento: number;
  rendimentoeu: number;
  numppt: number;
  pot_max: number;
}

interface Alert {
  type: string;
  message: string;
}

const ALERTS: Alert[] = [
  {
    type: 'info',
    message: 'Essa cidade não possui base  de dados para médias de temperatura',
  }
];

@Component({
  selector: 'app-dimensionador',
  templateUrl: './dimensionador.component.html',
  styleUrls: ['./dimensionador.component.css']
})
export class DimensionadorComponent implements OnInit {
  estados: any[] = [];
  nwestados: any[] = [];
  filteredEstados: any[];
  filteredCidades: any[];
  estadoselecionado: string;
  cidadeselecionada: string;;
  cidades: any[] = [];
  nwcidades: any[] = [];
  xestadoselecionado: any[];;
  xcidadeselecionada: any[];
  radiajson: any;
  temperaturas: any;
  data: any;


  finance = new Finance();
  digitapotinv1 = false;
  digitapotinv2 = false;
  ID = [];
  fileUrl;
  ultimacor:number;
  docxs;
  ma = 115;
  mi = 80;
  desativadow = false;
  progress = 0;
  mostrabuton = false;
  semcredito = false;
  validacao4 = {};
  dialogoso: any;
  aceitou = false;
  imagefinanceiro: string;
  imageindicadores: string;
  idempresa: string;
  creditoproposta: string;
  usoproposta: string;
  base64textString: string;
  anoatual = 2020;
  numdiames = 0;
  alerts: Alert[];
  custopv = 0;
  custoinstal = 0;
  custoproj = 0;
  customo = 0;
  custototal = 0;
  tarenergia = 0;
  reajtar = 5;
  taxjuros = 0;
  taxinf = 0;
  periodopay = 0;
  payback = 0;
  valortir = 0;
  retorno = 0;
  valorroi = 0;
  custoroi = 0;
  gasto = 0;
  periodofinan = 0;
  parcelasfinan = 0;
  taxafinan = 0;
  valorfinan = 0;
  encargos = 0;
  custosadicionais = 0;
  depreciacao = 0.8;
  parcelacarencias = 0;
  tarenergiaforaponta = 0;
  finam = false;
  minhacarteira: CARTEIRA[];
  descontado = 0;
  mediacon: any;
  placasserie = [];
  placaspar = [];
  placasserie2 = []; // INVERSOR 2
  placaspar2 = []; // INVERSOR 2
  energia: any;
  modelmodulos: [];
  IDSCONF1: IDSCONF1[] = [];
  IDSCONF2: IDSCONF1[] = [];  // INVERSOR 2
  idatual1 = 0;
  idatual2 = 0; // INVERSOR 2
  mpptatual = 0;
  allinversores = true;
  allinversores2 = true;
  multiploinvesor2 = false;
  mod: MODULOMODELO[] = [];
  modi: MODULOMODELO[] = [];
  modi2: MODULOMODELO[] = []; // INVERSOR 2
  paramsinv: any[] = [];
  paramsinv2: PAINV[] = []; // INVERSOR 2
  paramsmod: PAMOD[] = [];
  numeromppts: NUMMPPT[] = [];
  numeromppts2: NUMMPPT[] = [];
  inversorconf: INVERCONF[] = [];
  inversorconf2: INVERCONF[] = []; // INVERSOR 2
  inversorescascata1: Array<object> = [];
  inversorescascata2: Array<object> = []; // INVERSOR 2
  salvou = false;
  salvou2 = false;
  configura: Array<object> = [];
  configura2: Array<object> = []; // INVERSOR 2
  codmodulo: string;
  codinversor: string;
  codinversor2: string; // INVERSOR 2
  potinversorselec: any;
  potinversorselec2: any; // INVERSOR 2
  kwhdia: any;
  numinv1: number;
  numinv2: number; // INVERSOR 2
  numeroinversores1: number;
  numeroinversores2: any;
  tempcidade = -5;
  tempcidademax = 70;
  potnom: any;
  pot25p: any;
  numplacas: any;
  nomecliente: string;
  nomeresponsavel: string;
  telefoneresp: string;
  emailresponsavel: string;
  siteempresa: string;
  potkwp: any;
  qtdmod1 = 0;
  qtdmod2 = 0;
  areaplacas: any;
  radiacao: any;
  eficiencia: any = 80;
  aumento: any;
  aumentoconsumo: any = 0;
  consumoanual: any;
  razao: any = 100;
  potnecessaria: any;
  potenciasmodulo: any[];
  consumofixo: any;
  cidadeescolhida: string;
  estadoescolhido: string;
  percentfinan = 0;
  marcamodulos: string;
  potenciainversor1 = '';
  marcainversor1: string;
  potenciainversor2 = '';
  marcainver2 = '';
  jan: any;
  fev: any;
  mar: any;
  abr: any;
  mai: any;
  jun: any;
  jul: any;
  ago: any;
  set: any;
  out: any;
  nov: any;
  dez: any;
  gerjan = 0;
  gerfev = 0;
  germar = 0;
  gerabr = 0;
  germai = 0;
  gerjun = 0;
  gerjul = 0;
  gerago = 0;
  gerset = 0;
  gerout = 0;
  gernov = 0;
  gerdez = 0;
  gerjan2 = 0;
  gerfev2 = 0;
  germar2 = 0;
  gerabr2 = 0;
  germai2 = 0;
  gerjun2 = 0;
  gerjul2 = 0;
  gerago2 = 0;
  gerset2 = 0;
  gerout2 = 0;
  gernov2 = 0;
  gerdez2 = 0;
  t1 = 0;
  t2 = 0;
  t3 = 0;
  t4 = 0;
  t5 = 0;
  t6 = 0;
  t7 = 0;
  t8 = 0;
  t9 = 0;
  t10 = 0;
  t11 = 0;
  t12 = 0;
  ajan = 0;
  afev = 0;
  amar = 0;
  aabr = 0;
  amai = 0;
  ajun = 0;
  ajul = 0;
  aago = 0;
  aset = 0;
  aout = 0;
  anov = 0;
  adez = 0;
  ajan2 = 0;
  afev2 = 0;
  amar2 = 0;
  aabr2 = 0;
  amai2 = 0;
  ajun2 = 0;
  ajul2 = 0;
  aago2 = 0;
  aset2 = 0;
  aout2 = 0;
  anov2 = 0;
  adez2 = 0;
  rad1: number;
  rad2: number;
  rad3: number;
  rad4: number;
  rad5: number;
  rad6: number;
  rad7: number;
  rad8: number;
  rad9: number;
  rad10: number;
  rad11: number;
  rad12: number;
  modeloinversor1: any;
  modeloplacas: any;
  marcamodul: any;
  selectedValue4: any;
  modelinv2 = null;
  potinversores: number;
  potenciafvselec: number;
  semtemperatura = false;
  conta = 0;
  compen = 0;
  economia = 0;
  analise = '';
  totalplacas = 0;
  totalinversores = 0;

  tipos: Potencia[] = [
    { value: 225, viewValue: '225W' },
    { value: 230, viewValue: '230W' },
    { value: 235, viewValue: '235W' },
    { value: 240, viewValue: '240W' },
    { value: 245, viewValue: '245W' },
    { value: 250, viewValue: '250W' },
    { value: 255, viewValue: '255W' },
    { value: 260, viewValue: '260W' },
    { value: 265, viewValue: '265W' },
    { value: 270, viewValue: '270W' },
    { value: 275, viewValue: '275W' },
    { value: 280, viewValue: '285W' },
    { value: 295, viewValue: '295W' },
    { value: 300, viewValue: '300W' },
    { value: 305, viewValue: '305W' },
    { value: 310, viewValue: '310W' },
    { value: 315, viewValue: '315W' },
    { value: 320, viewValue: '320W' },
    { value: 325, viewValue: '325W' },
    { value: 330, viewValue: '330W' },
    { value: 335, viewValue: '335W' },
    { value: 340, viewValue: '340W' },
    { value: 345, viewValue: '345W' },
    { value: 350, viewValue: '350W' },
    { value: 355, viewValue: '355W' },
    { value: 360, viewValue: '360W' },
    { value: 365, viewValue: '365W' },
    { value: 370, viewValue: '370W' },
    { value: 375, viewValue: '375W' },
    { value: 380, viewValue: '380W' },
    { value: 385, viewValue: '385W' },
    { value: 390, viewValue: '390W' },
    { value: 395, viewValue: '395W' },
    { value: 400, viewValue: '400W' },
  ];
  estadoselecionado: string;
  parcelafinanciamento = 0;
  inputmmpt = 0;
  cidadeselecionada: string;
  radiacaoescolhida: any;
  potmodulo: number;
  marcas: any[];
  modelosinver: any[];
  marcasinver: any[];
  marcasinver2: any[]; // INVERSOR2
  modelos: any[];
  custodisponibilidade = 0;
  disponibile: number[] = [];
  dias: any[] = [];
  EprodAno: number[] = [];
  EaccumAno: number[] = [];
  EconsumAno: number[] = [];
  Receita: number[] = [];
  EconoSimuAno: number[] = [];
  EconoCompAno: number[] = [];
  JurosFinan: number[] = [];
  JurosAtivosAno: number[] = [];
  FluxoCaixadesc: number[];
  saidasAno: number[] = [];
  CustoDisp: number[] = [];
  CustosAnu: number[] = [];
  DespExtra: number[] = [];
  rendimentosacu: number[] = [];
  ParcelaFinan: number[] = [];
  taxajurosfinan: number;
  capital: number[] = [];
  FluxoCaixa: number[] = [];
  FluxoCaixaacc: number[] = [];
  CapProprio: number[] = [];
  MonteEco: number[] = [];
  Taxarend: number[] = [];
  VPL: number[] = [];
  TIR: number[] = [];
  solicitante: string;
  potacc = 0;
  ANOS: any[] = [];
  edescontado: 0;
  valorkwp = 0;
  custocordisp = 0;
  potinversor: any[] = [];
  potinversor2: any[]; // INVERSOR 2
  potenominal = 0;
  potepico: 0;
  tipousina = 'Microgeração';
  produacaopiano: string;
  potdimensionada: number;
  miniv: number;
  mainv: number;
  graph1 = true;
  radiajson: any;
  radiajson2:any;
  displayrs = true;
  capturedImage: any;
  temperaturas: any;
  todashomologacoes: todosdados[];
  homologacaoselecionada: string;
  spinnerdados = '';
  nodes: TreeNode[];
  displayx = false;
  current = 1;
  checked= false
  recordes = 5;
  basicOptions: any;
  barChartData: ChartDataSets[];
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { yAxes: [{ ticks: { min: 0, stepSize: 200 } }] }
    ,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors: Color[] = [
    { backgroundColor: 'green' },
    { backgroundColor: 'blue' },
  ]
  public barChartLabels: Label[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 50; // must use `function` and not => because of `this`
        };
      }
    }
  ];
  
  constructor(private service: ProemanagerService,private router: Router,
    private injector: Injector) { 
    
  }

  ngOnInit(): void {
    this.radiajasonx()
    this.data = {
      labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      datasets: [
          {
              responsive: true,
              label: 'Geração',
              data: [0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0 ],
              backgroundColor: '#42A5F5'

          },
          {
              responsive: true,
              label: 'Consumo',
              data:  [0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0 ],
              backgroundColor: '#FFA726'
          }
      ]
  }
    this.barChartData = [
      // tslint:disable-next-line: max-line-length
      { data: [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.nov, this.dez], label: 'Consumo' },
      { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Geração Estimada' }
    ];
      this.basicOptions = {
          legend: {
              labels: {
                  fontColor: '#42A5F5'
              }
          },
          scales: {
              xAxes: [{
                  ticks: {
                      fontColor: '#42A5F5',
                      size:100
                  },
                  gridLines: {
                      color: 'rgba(255,255,255,0.2)'
                  }
              }],
              yAxes: [{
                  ticks: {
                      fontColor: '#42A5F5'
                  },
                  gridLines: {
                      color: 'rgba(255,255,255,0.2)'
                  }
              }]
          }
      };
    this.nodes = [
      {
          key: '0',
          label: 'Análise Mensal',
          icon: 'pi pi-calendar',
          children: [
              {key: '0-0', label: 'What is Angular', data:'https://angular.io', type: 'url'},
          ]
      }
  ];
    this.service.getradiacaoglobal()
    .subscribe(dados => {
      if (dados !== this.radiajson) {
        this.radiajson = dados;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.radiajson.length; i++) {
          this.estados.push(this.radiajson[i]['UF']);
        }
        this.estados = this.estados.sort();
        this.estados = this.estados.filter(
          (thing, i, arr) => arr.findIndex(t => t === thing) === i);
      }
      for (let i = 0; i < this.estados.length; i++) {
        this.nwestados.push({name:this.estados[i], code:this.estados[i] });
      }
      
    });
  }

  radiajasonx(){
    this.service.getradiacao()
    .subscribe(dados => {
      if (dados !== this.radiajson2) {
        this.radiajson2 = dados;
        // tslint:disable-next-line: prefer-for-o
      
    }});
  }
  doisinv(){
  if(this.checked === true){
  this.recordes = 6
  }
  if(this.checked === false){
    this.recordes = 5
    }
  }
   //////////////////////////////////////////////////////////
  //  CALCULA POTENCIA INSTALADA
  //////////////////////////////////////////////////////////
  calcpot() {
    if (parseFloat(this.aumentoconsumo) > 0) {
      console.log('primeiro if')
      console.log(this.aumentoconsumo)
      this.aumento = 1 + parseFloat(this.aumentoconsumo) / 100;
      console.log(this.aumento)
      this.energia = this.kwhdia * (parseFloat(this.razao) / 100) * this.aumento / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
      this.energia =  this.energia
      this.potnecessaria = parseFloat(this.energia).toFixed(2);
    }
    if (parseFloat(this.aumentoconsumo) === 0) {
      console.log('segundo if')
      this.energia = this.kwhdia  * (parseFloat(this.razao) / 100)  / ((parseFloat(this.eficiencia) / 100) * this.radiacaoescolhida);
      this.energia =  this.energia
      this.potnecessaria = parseFloat(this.energia).toFixed(2);
    }
    // tslint:disable-next-line: max-line-length
    console.log(parseFloat(this.razao) / 100) 
    console.log((parseFloat(this.eficiencia) / 100))
    console.log(this.radiacaoescolhida)
    console.log(this.kwhdia)

  }

  mudamodinv1(i) {
    const index = i - 1;

    this.marcainversor1 = this.marcainversor1 + ' - ' + this.modeloinversor1;
  }

  mudamodinv2(i) {
    const index = i - 1;
    console.log(this.modi2[index].modelo);
    this.modelinv2 = this.modi2[index].modelo;
    this.marcainver2 = this.marcainver2 + '-' + this.modelinv2;
  }
  muda(a){
    if(a.page === 0 ||a.page === 1||a.page === 2 ){
      console.log('igual')
      this.current = a.page +1
    }

    if(a.page === 3 ){
      console.log('potenciainversores')
      this.current = a.page +1
      this.potenciainversores();
    }
    

    if(a.page === 4 && this.checked !== true){
      this.chart()
      this.current = a.page +1
    }
    if(a.page === 5){
      this.current = a.page +1
      //this.potenciainversores();
      //this.potenciainversores2();
    }
    if(a.page === 4 && this.checked === true){
      this.current = 100
    }
  }

  potgeracaox1() {
    this.gerjan = 0;
    this.gerfev = 0;
    this.germar = 0;
    this.gerabr = 0;
    this.germai = 0;
    this.gerjun = 0;
    this.gerjul = 0;
    this.gerago = 0;
    this.gerset = 0;
    this.gerout = 0;
    this.gernov = 0;
    this.gerdez = 0;
    if (this.semtemperatura === true) {
      for (let i = 0; i < this.numinv1; i++) {
        console.log(this.paramsinv)
    
          // tslint:disable-next-line: max-line-length
          this.gerjan = this.gerjan + (this.paramsmod[0].area) * this.numplacas* (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerfev = this.gerfev + (this.paramsmod[0].area) * this.numplacas * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
          // tslint:disable-next-line: max-line-length
          this.germar = this.germar + (this.paramsmod[0].area) * this.numplacas * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerabr = this.gerabr + (this.paramsmod[0].area) * this.numplacas * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.germai = this.germai + (this.paramsmod[0].area) * this.numplacas * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjun = this.gerjun + (this.paramsmod[0].area) * this.numplacas * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjul = this.gerjul + (this.paramsmod[0].area) * this.numplacas * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerago = this.gerago + (this.paramsmod[0].area) * this.numplacas * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerset = this.gerset + (this.paramsmod[0].area) * this.numplacas * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerout = this.gerout + (this.paramsmod[0].area) * this.numplacas * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gernov = this.gernov + (this.paramsmod[0].area) * this.numplacas * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerdez = this.gerdez + (this.paramsmod[0].area) * this.numplacas * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
      }
    }

    if (this.semtemperatura === false) {
      for (let i = 0; i < this.numinv1; i++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.numplacas) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
          // tslint:disable-next-line: max-line-length
          this.germar = this.germar + (this.paramsmod[0].area) * (this.numplacas) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.numplacas) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
          // tslint:disable-next-line: max-line-length
          this.germai = this.germai + (this.paramsmod[0].area) * (this.numplacas) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.numplacas) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.numplacas) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerago = this.gerago + (this.paramsmod[0].area) * (this.numplacas) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerset = this.gerset + (this.paramsmod[0].area) * (this.numplacas) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerout = this.gerout + (this.paramsmod[0].area) * (this.numplacas) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
          // tslint:disable-next-line: max-line-length
          this.gernov = this.gernov + (this.paramsmod[0].area) * (this.numplacas) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.numplacas) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));
      }
    }
    // tslint:disable-next-line: max-line-length
    //this.data.datasets[1].data = [this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo];
    console.log(this.data)
    this.barChartData[1].data = [Math.trunc(this.gerjan), Math.trunc(this.gerfev), Math.trunc(this.germar), Math.trunc(this.gerabr), Math.trunc(this.germai), Math.trunc(this.gerjun), Math.trunc(this.gerjul), Math.trunc(this.gerago), Math.trunc(this.gerset), Math.trunc(this.gerout), Math.trunc(this.gernov), Math.trunc(this.gerdez)];
  }

  potgeracao() {
    console.log('potegera')
    this.gerjan = 0;
    this.gerfev = 0;
    this.germar = 0;
    this.gerabr = 0;
    this.germai = 0;
    this.gerjun = 0;
    this.gerjul = 0;
    this.gerago = 0;
    this.gerset = 0;
    this.gerout = 0;
    this.gernov = 0;
    this.gerdez = 0;
    // tslint:disable-next-line: prefer-for-of
    if (this.semtemperatura === true) {
      for (let i = 0; i < this.numinv1; i++) {
        for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
          // tslint:disable-next-line: max-line-length
          this.germar = this.germar + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.germai = this.germai + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerago = this.gerago + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerset = this.gerset + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerout = this.gerout + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gernov = this.gernov + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;

        }
      }
    }
    if (this.semtemperatura === false) {

      for (let i = 0; i < this.numinv1; i++) {
        for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan = this.gerjan + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerfev = this.gerfev + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
          // tslint:disable-next-line: max-line-length
          this.germar = this.germar + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerabr = this.gerabr + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
          // tslint:disable-next-line: max-line-length
          this.germai = this.germai + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjun = this.gerjun + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjul = this.gerjul + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerago = this.gerago + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerset = this.gerset + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerout = this.gerout + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
          // tslint:disable-next-line: max-line-length
          this.gernov = this.gernov + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerdez = this.gerdez + (this.paramsmod[0].area) * (this.inversorescascata1[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));

        }
      }
    }
    // tslint:disable-next-line: max-line-length
    //this.data.datasets[0].data= [Math.trunc(this.gerjan), Math.trunc(this.gerfev), Math.trunc(this.germar), Math.trunc(this.gerabr), Math.trunc(this.germai), Math.trunc(this.gerjun), Math.trunc(this.gerjul), Math.trunc(this.gerago), Math.trunc(this.gerset), Math.trunc(this.gerout), Math.trunc(this.gernov), Math.trunc(this.gerdez)];
    this.barChartData[1].data = [Math.trunc(this.gerjan), Math.trunc(this.gerfev), Math.trunc(this.germar), Math.trunc(this.gerabr), Math.trunc(this.germai), Math.trunc(this.gerjun), Math.trunc(this.gerjul), Math.trunc(this.gerago), Math.trunc(this.gerset), Math.trunc(this.gerout), Math.trunc(this.gernov), Math.trunc(this.gerdez)];
    console.log(this.data)
  }

  potgeracao2() {
    this.gerjan2 = 0;
    this.gerfev2 = 0;
    this.germar2 = 0;
    this.gerabr2 = 0;
    this.germai2 = 0;
    this.gerjun2 = 0;
    this.gerjul2 = 0;
    this.gerago2 = 0;
    this.gerset2 = 0;
    this.gerout2 = 0;
    this.gernov2 = 0;
    this.gerdez2 = 0;
    // tslint:disable-next-line: prefer-for-of
    if (this.semtemperatura === true) {
      for (let i = 0; i < this.numinv2; i++) {
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan2 = this.gerjan2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerfev2 = this.gerfev2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29;
          // tslint:disable-next-line: max-line-length
          this.germar2 = this.germar2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerabr2 = this.gerabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.germai2 = this.germai2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjun2 = this.gerjun2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerjul2 = this.gerjul2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerago2 = this.gerago2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gerset2 = this.gerset2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerout2 = this.gerout2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;
          // tslint:disable-next-line: max-line-length
          this.gernov2 = this.gernov2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30;
          // tslint:disable-next-line: max-line-length
          this.gerdez2 = this.gerdez2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31;

        }
      }
    }
    if (this.semtemperatura === false) {

      for (let i = 0; i < this.numinv2; i++) {
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          // tslint:disable-next-line: max-line-length
          this.gerjan2 = this.gerjan2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t1 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerfev2 = this.gerfev2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad2) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 29 * (1 + (this.paramsmod[0].cft / 100) * (this.t2 - 25));
          // tslint:disable-next-line: max-line-length
          this.germar2 = this.germar2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad3) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t3 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerabr2 = this.gerabr2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad4) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t4 - 25));
          // tslint:disable-next-line: max-line-length
          this.germai2 = this.germai2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad5) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t5 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjun2 = this.gerjun2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad7) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t6 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerjul2 = this.gerjul2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad8) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t7 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerago2 = this.gerago2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad9) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t8 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerset2 = this.gerset2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad10) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t9 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerout2 = this.gerout2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad11) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t10 - 25));
          // tslint:disable-next-line: max-line-length
          this.gernov2 = this.gernov2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad12) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 30 * (1 + (this.paramsmod[0].cft / 100) * (this.t11 - 25));
          // tslint:disable-next-line: max-line-length
          this.gerdez2 = this.gerdez2 + (this.paramsmod[0].area) * (this.inversorescascata2[i][j].qtd_modulos) * (this.rad1) * ((this.eficiencia) / 100) * (this.paramsinv2[0]['rendimentoeu']) * (this.paramsmod[0].rendimento) * 31 * (1 + (this.paramsmod[0].cft / 100) * (this.t12 - 25));

        }
      }
    }
    // tslint:disable-next-line: max-line-length
    //this.data.datasets[0].data= [Math.trunc(this.gerjan + this.gerjan2), Math.trunc(this.gerfev + this.gerfev2), Math.trunc(this.germar + this.germar2), Math.trunc(this.gerabr + this.gerabr2), Math.trunc(this.germai + this.germai2), Math.trunc(this.gerjun + this.gerjun2), Math.trunc(this.gerjul + this.gerjul2), Math.trunc(this.gerago + this.gerago2), Math.trunc(this.gerset + this.gerset2), Math.trunc(this.gerout + this.gerout2), Math.trunc(this.gernov + this.gernov2), Math.trunc(this.gerdez + this.gerdez2)];
  }
  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DO INVERSOR 2
  //////////////////////////////////////////////////////////
  parainver2(mods) {
    this.inversorescascata2 = [];
    this.codinversor2 = mods;
    this.paramsinv2 = [];
    this.IDSCONF2 = [];
    this.numeromppts2 = [];
    this.service.parametrosinversores({ codigo: mods })
      .subscribe(dados => {
        this.paramsinv2 = dados;
        for (let i = 0; i < this.paramsinv2[0]['numppt']; i++) {
          const a = String(i + 1);
          const codigomppt = 'MPPT ' + a;
          const hrefe = '#' + codigomppt;
          this.placasserie2.push(0);
          this.placaspar2.push(0);
          // parametros dos mppt
          this.numeromppts2.push({
            numofmppt: codigomppt,
            href: hrefe,
            cor_max: (this.paramsinv2[0]['cor_str']),
            vmp_min: this.paramsinv2[0]['vmp_min'],
            vmp_max: this.paramsinv2[0]['vmp_max'],
            pot_max: ((this.paramsinv2[0]['ppv_max']) / 10) / 2,
            qtd_mod_ser: 0,
            qtd_mod_par: 0,
            voc: 0,
            vnom: 0,
            Ic: 0,
            potconfigurada: 0,
            qtd_modulos: 0,
            pottotalconfigurada: 0,
          });
        }
        this.inversorconf2.push({
          coinv: this.numeromppts2,
          potencia: this.paramsinv2[0]['vmp_max'],
          rendimento: this.paramsinv2[0]['rendimento'],
          rendimentoeu: this.paramsinv2[0]['rendimentoeu'],
          numppt: this.paramsinv2[0]['numppt'],
          pot_max: this.paramsinv2[0]['ppv_max'],
        });
        this.salvainversor2();
      });
    for (let i = 0; i < this.numinv2; i++) {
      this.IDSCONF2.push({ value: i, viewValue: 'Inversor ' + (i + 1) });
    }

  }

  calcinv21() {
    this.numinv2 = this.numinv2 + 1;
    const is = 'Inv' + (this.inversorescascata2.length + 1);
    this.IDSCONF2.push({ value: this.IDSCONF2.length, viewValue: 'Inversor ' + (this.IDSCONF2.length + 1) });
    this.configura2 = [];
    for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.configura2.push({
          inversor: 'Inversor ' + (this.numinv2 - 1),
          numofmppt: codigomppt,
          qtd_mod_ser: 0,
          qtd_mod_par: 0,
          voc: 0 * this.paramsmod[0].tensaonom,
          vnom: 0 * this.paramsmod[0].tensaovazio,
          Ic: 0 * this.paramsmod[0].correntenom,
          potconfigurada: 0 * this.numeromppts2[j].qtd_mod_par * this.potnom,
          pottotalconfigurada: 0,
          qtd_modulos: 0 * this.numeromppts2[j].qtd_mod_par,
        });
        this.qtdmod2 = this.qtdmod2 + this.configura[j]['qtd_modulos'];
      }
    this.inversorescascata2.push(this.configura2);

  }
  calcinv22() {
    this.numinv2 = this.numinv2 - 1;
    this.inversorescascata2.pop();
    this.IDSCONF2.pop();
  }
  /////////////////////////////////////////////////////////
  // SALVA DADOS DO INVERSOR 2
  //////////////////////////////////////////////////////////
  salvainversor2() {
    this.configura2 = [];
    this.qtdmod2 = 0;
    if (this.allinversores2 === true) {
      this.inversorescascata2 = [];
      for (let i = 0; i < this.numinv2; i++) {
        this.configura2 = [];
        for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
          const a = String(j + 1);
          const codigomppt = 'MPPT ' + a;
          this.configura2.push({
            inversor: 'Inversor ' + (i + 1),
            numofmppt: codigomppt,
            qtd_mod_ser: this.numeromppts2[j].qtd_mod_ser,
            qtd_mod_par: this.numeromppts2[j].qtd_mod_par,
            voc: this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaonom,
            vnom: this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaovazio,
            Ic: this.numeromppts2[j].qtd_mod_par * this.paramsmod[0].correntenom,
            potconfigurada: this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par * this.potnom,
            qtd_modulos: this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par,
            pottotalconfigurada: 0
          });
          this.qtdmod2 = this.qtdmod2 + this.configura2[j]['qtd_modulos'];
        }
        let totalpot = 0;
        for (let k = 0; k < this.paramsinv2[0]['numppt']; k++) {
        console.log(this.configura2[k]['potconfigurada'])
        totalpot = totalpot + this.configura2[k]['potconfigurada'] }
        this.configura2[0]['pottotalconfigurada'] = parseFloat(totalpot.toFixed(2));
        this.inversorescascata2.push(this.configura2);
      }
    } else {
      for (let j = 0; j < this.paramsinv2[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.inversorescascata2[this.idatual2][j].inversor = 'Inversor ' + (this.idatual2)
        this.inversorescascata2[this.idatual2][j].numofmppt = codigomppt;
        this.inversorescascata2[this.idatual2][j].qtd_mod_ser = this.numeromppts2[j].qtd_mod_ser;
        this.inversorescascata2[this.idatual2][j].qtd_mod_par = this.numeromppts2[j].qtd_mod_par;
        this.inversorescascata2[this.idatual2][j].voc = (this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaonom);
        this.inversorescascata2[this.idatual2][j].vnom = this.numeromppts2[j].qtd_mod_ser * this.paramsmod[0].tensaovazio;
        // tslint:disable-next-line: no-unused-expression
        this.inversorescascata2[this.idatual2][j].Ic = this.numeromppts2[j].qtd_mod_par * this.paramsmod[0].correntenom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata2[this.idatual2][j].potconfigurada = this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par * this.potnom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata2[this.idatual2][j].qtd_modulos = this.numeromppts2[j].qtd_mod_ser * this.numeromppts2[j].qtd_mod_par;
        this.qtdmod2 = this.qtdmod2 + this.inversorescascata2[this.idatual2][j].qtd_modulos;
      }
    }
    this.salvou2 = true;
  }
  /////////////////////////////////////////////////////////
  // BUSCA MARCA INVERSOR2 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  marcainversor2(a) {
    var potencia = a
    this.potinversorselec2 = a;
    console.log('potenica',potencia)
    this.modi2 = [];
    this.marcasinver2 = [];
    var value2 = potencia.toString();
    if (value2.indexOf('.') !== -1 && value2.indexOf(',') !== -1) {
        if (value2.indexOf('.') <  value2.indexOf(',')) {
           //inglês
           return parseFloat(value2.replace(/,/gi,''));
        } else {
          //português
          value2 = parseFloat(value2.replace(/./gi,'').replace(/,/gi,'.'));
        }      
    } else {
     value2 = parseFloat(value2);
    }
    this.numinv2 = Math.trunc(this.potkwp / value2);
    if (this.numinv2 === 0) { this.numinv2 = 1; }
    this.service.marcainver({ pot: potencia })
      .subscribe(dados => {
        if (dados ) {
         var dadox = dados.sort();
          for (let i = 0; i < dadox.length; i++) {
            this.marcasinver2.push({ marca: dadox[i], codice2: dadox[i] })
          }
        }
      });
      console.log(this.marcasinver2)
  }


  /////////////////////////////////////////////////////////
  // BUSCA MODELO INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modeloinversor2(mar) {
    console.log(mar)
    this.modi2 = [];
    this.placasserie2 = [];
    this.placaspar2 = [];
    this.service.modeloinv({ marca: mar, pot: this.potinversorselec2 })
      .subscribe(dados => {
        if (dados !== this.modi2) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.modi2.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }

/////////////////////////////////////////////////////////
  // BUSCA POTENCIA INVERSORES2 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  potenciainversores2() {
    this.modi2 = [];
    this.service.potinv({ m: this.miniv, max: this.mainv })
      .subscribe(dados => {
        if (dados !== this.potinversor2) {
          this.potinversor2 = dados.sort((a, b) => a - b);
        }
      });
  }
  /////////////////////////////////////////////////////////
  // BUSCA POTENCIA INVERSORES BANCO DE DADOS
  //////////////////////////////////////////////////////////
  potenciainversores() {
    this.modi = [];
    this.miniv = Math.trunc(this.potkwp);
    this.mainv = Math.ceil(1.15 * this.potkwp);
    this.service.potinv({ m: this.miniv, max: this.mainv })
      .subscribe(dados => {
        if (dados ) {
          console.log(dados)

          var potencs = dados.sort((a, b) => a - b);
          for (let i = 0; i < potencs.length; i++) {
            this.potinversor.push({code:potencs[i],name:potencs[i]});
          }
        }
        console.log(this.potinversor)
      });

      
  }
  /////////////////////////////////////////////////////////
  // BUSCA MARCA INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  marcainversor(potencia) {
    this.potinversorselec = potencia;
    this.modi = [];
    this.marcasinver = [];
    var value = potencia.toString();
    if (value.indexOf('.') !== -1 && value.indexOf(',') !== -1) {
        if (value.indexOf('.') <  value.indexOf(',')) {
           //inglês
           return parseFloat(value.replace(/,/gi,''));
        } else {
          //português
           value = parseFloat(value.replace(/./gi,'').replace(/,/gi,'.'));
        }      
    } else {
       value = parseFloat(value);
    }
    console.log(value);
    this.numinv1 = Math.trunc(this.potkwp / value);
    if (this.numinv1 === 0) { this.numinv1 = 1; }
    this.service.marcainver({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcasinver) {
          var dadosX = dados.sort();
          for (let i = 0; i < dadosX.length; i++) {
            this.marcasinver.push({ marca: dadosX[i], codice2: dadosX[i] })
          }
        }
      });
      console.log(this.marcasinver)
  }


  calcinv1() {
    this.numinv1 = this.numinv1 + 1;
    const is = 'Inv' + (this.inversorescascata1.length + 1);
    this.IDSCONF1.push({ value: this.IDSCONF1.length, viewValue: 'Inversor ' + (this.IDSCONF1.length + 1) });
    this.configura = [];
    for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.configura.push({
          inversor: 'Inversor ' + (this.numinv1 - 1),
          numofmppt: codigomppt,
          qtd_mod_ser: 0,
          qtd_mod_par: 0,
          voc: 0 * this.paramsmod[0].tensaonom,
          vnom: 0 * this.paramsmod[0].tensaovazio,
          Ic: 0 * this.paramsmod[0].correntenom,
          potconfigurada: 0 * this.numeromppts[j].qtd_mod_par * this.potnom,
          pottotalconfigurada: 0,
          qtd_modulos: 0 * this.numeromppts[j].qtd_mod_par,
        });
        this.qtdmod1 = this.qtdmod1 + this.configura[j]['qtd_modulos'];
      }
    this.inversorescascata1.push(this.configura);
    console.log(this.inversorescascata1);
  }
  calcinv2() {
    this.numinv1 = this.numinv1 - 1;
    this.inversorescascata1.pop();
    this.IDSCONF1.pop();
  }
  /////////////////////////////////////////////////////////
  // BUSCA MODELO INVERSOR1 BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modeloinversor(mar) {
    console.log(mar)
    this.modi = [];
    this.placasserie = [];
    this.placaspar = [];
    this.service.modeloinv({ marca: mar, pot: this.potinversorselec })
      .subscribe(dados => {
        if (dados !== this.modi) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.modi.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }



  /////////////////////////////////////////////////////////
  // CALCULA FVS
  //////////////////////////////////////////////////////////
  calcfv(pot) {
    this.modelos = [];
    this.marcas = [];
    this.mod = [];
    this.potkwp = 0;
    this.areaplacas = 0;
    this.potnom = (pot / 1000)
    this.potnom = this.potnom.toFixed(3);
    this.numplacas = parseFloat((this.potnecessaria / this.potnom).toFixed(0));
    this.potkwp = this.numplacas * this.potnom;
    this.areaplacas = this.numplacas * 2;
    while (this.potkwp <= this.potnecessaria) {
      this.numplacas = this.numplacas + 1;
      this.potkwp = this.numplacas * this.potnom;
      this.areaplacas = this.numplacas * 2;
    }
    this.potkwp = (this.numplacas * this.potnom).toFixed(3);
    this.potenciafvselec = this.potnom * 1000;
    this.potmodulo = this.potnom * 1000;
    this.potenciamodulos(this.potmodulo);
  }
  /////////////////////////////////////////////////////////
  // CALCULA POTÊNCIA DOS MÓDULOS
  //////////////////////////////////////////////////////////

  potenciamodulos(potencia) {
    this.service.potmod({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcas) {
          var marc = dados.sort();
        }
        for(let i = 0; i < marc.length; i++) {
          this.marcas.push({name:marc[i],code:marc[i]})
        }
        
      });
  }
  trocapagina(){
      const routerService = this.injector.get(Router);
      const ngZone = this.injector.get(NgZone);
      ngZone.run(() => {
        routerService.navigate(['/myhomols'], { replaceUrl: true, skipLocationChange: true  });
  
    })
  }
  /////////////////////////////////////////////////////////
  //  BUSCA MODELO MODULOS BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modelomodulos(mar) {
    this.mod = [];
    this.service.modelomodu({ marca: mar, pot: this.potmodulo })
      .subscribe(dados => {
        if (dados !== this.mod) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.mod.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }

  calcfv2() {
    this.numplacas = this.numplacas + 1;
    this.potkwp = (this.numplacas * (this.potmodulo) / 1000).toFixed(3);
    this.areaplacas = this.numplacas * 2;
  }
  calcfv3() {
    this.numplacas = this.numplacas - 1;
    this.potkwp = (this.numplacas * (this.potmodulo) / 1000).toFixed(3);
    this.areaplacas = this.numplacas * 2;
  }
  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DOS MODULOS
  //////////////////////////////////////////////////////////
  parametromudolo(mods) {
    this.codmodulo = mods;
    this.paramsmod = [];
    this.service.parametrosmodulo({ codigo: mods })
      .subscribe(dados => {
        this.paramsmod = dados;

      });
  }

  /////////////////////////////////////////////////////////
  // BUSCA MPARAMETROS DO INVERSOR 1
  //////////////////////////////////////////////////////////
  parainver(mods) {
    console.log(mods)
    this.inversorescascata1 = [];
    this.codinversor = mods;
    this.paramsinv = [];
    this.IDSCONF1 = [];
    this.numeromppts = [];
    this.service.parametrosinversores({ codigo: mods })
      .subscribe(dados => {
        this.paramsinv = dados;
        console.log(this.paramsinv)
        this.potgeracaox1()
        for (let i = 0; i < this.paramsinv[0]['numppt']; i++) {
          const a = String(i + 1);
          const codigomppt = 'MPPT ' + a;
          const hrefe = '#' + codigomppt;
          this.placasserie.push(0);
          this.placaspar.push(0);
          // parametros dos mppt
          this.numeromppts.push({
            numofmppt: codigomppt,
            href: hrefe,
            cor_max: (this.paramsinv[0]['cor_str']),
            vmp_min: this.paramsinv[0]['vmp_min'],
            vmp_max: this.paramsinv[0]['vmp_max'],
            pot_max: ((this.paramsinv[0]['pot_max']) / 10) / 2,
            qtd_mod_ser: 0,
            qtd_mod_par: 0,
            voc: 0,
            vnom: 0,
            Ic: 0,
            potconfigurada: 0,
            qtd_modulos: 0,
            pottotalconfigurada: 0
          });
        }
        this.inversorconf.push({
          coinv: this.numeromppts,
          potencia: this.paramsinv[0]['vmp_max'],
          rendimento: this.paramsinv[0]['rendimento'],
          rendimentoeu: this.paramsinv[0]['rendimentoeu'],
          numppt: this.paramsinv[0]['numppt'],
          pot_max: this.paramsinv[0]['ppv_max'],
        });
        console.log(this.inversorconf)
        this.salvainversor();
      });
    for (let i = 0; i < this.numinv1; i++) {
      this.IDSCONF1.push({ value: i, viewValue: 'Inversor ' + (i + 1) });
    }
  }
 /////////////////////////////////////////////////////////
  // SALVA DADOS DO INVERSOR 1
  //////////////////////////////////////////////////////////
  salvainversor() {
    this.configura = [];
    this.qtdmod1 = 0;
    if (this.allinversores === true) {
      this.inversorescascata1 = [];
      for (let i = 0; i < this.numinv1; i++) {
        this.configura = [];
        for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
          const a = String(j + 1);
          const codigomppt = 'MPPT ' + a;
          this.configura.push({
            inversor: 'Inversor ' + (i + 1),
            numofmppt: codigomppt,
            qtd_mod_ser: this.numeromppts[j].qtd_mod_ser,
            qtd_mod_par: this.numeromppts[j].qtd_mod_par,
            voc: this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaonom,
            vnom: this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaovazio,
            Ic: this.numeromppts[j].qtd_mod_par * this.paramsmod[0].correntenom,
            potconfigurada: this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par * this.potnom,
            qtd_modulos: this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par,
            pottotalconfigurada: 0
          });
          this.qtdmod1 = this.qtdmod1 + this.configura[j]['qtd_modulos'];
        }
        let totalpot = 0;
        for (let k = 0; k < this.paramsinv[0]['numppt']; k++) {
        console.log(this.configura[k]['potconfigurada'])
        totalpot = totalpot + this.configura[k]['potconfigurada'] }
        this.configura[0]['pottotalconfigurada'] = parseFloat(totalpot.toFixed(2));
        this.inversorescascata1.push(this.configura);
        console.log(this.inversorescascata1);
      }
    } else {
      for (let j = 0; j < this.paramsinv[0]['numppt']; j++) {
        const a = String(j + 1);
        const codigomppt = 'MPPT ' + a;
        this.inversorescascata1[this.idatual1][j].inversor = 'Inversor ' + (this.idatual1)
        this.inversorescascata1[this.idatual1][j].numofmppt = codigomppt;
        this.inversorescascata1[this.idatual1][j].qtd_mod_ser = this.numeromppts[j].qtd_mod_ser;
        this.inversorescascata1[this.idatual1][j].qtd_mod_par = this.numeromppts[j].qtd_mod_par;
        this.inversorescascata1[this.idatual1][j].voc = (this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaonom);
        this.inversorescascata1[this.idatual1][j].vnom = this.numeromppts[j].qtd_mod_ser * this.paramsmod[0].tensaovazio;
        // tslint:disable-next-line: no-unused-expression
        this.inversorescascata1[this.idatual1][j].Ic = this.numeromppts[j].qtd_mod_par * this.paramsmod[0].correntenom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata1[this.idatual1][j].potconfigurada = this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par * this.potnom;
        // tslint:disable-next-line: max-line-length
        this.inversorescascata1[this.idatual1][j].qtd_modulos = this.numeromppts[j].qtd_mod_ser * this.numeromppts[j].qtd_mod_par;
        this.qtdmod1 = this.qtdmod1 + this.inversorescascata1[this.idatual2][j].qtd_modulos;
      }
    }
    this.salvou = true;
  }


  //////////////////////////////////////////////////////////
  //  CALCULA CONSUMO
  //////////////////////////////////////////////////////////

    chart(){
    this.data.datasets[1].data = [this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo];
    this.data.datasets[0].data= [Math.trunc(this.gerjan), Math.trunc(this.gerfev), Math.trunc(this.germar), Math.trunc(this.gerabr), Math.trunc(this.germai), Math.trunc(this.gerjun), Math.trunc(this.gerjul), Math.trunc(this.gerago), Math.trunc(this.gerset), Math.trunc(this.gerout), Math.trunc(this.gernov), Math.trunc(this.gerdez)];
  }
  consumo() {
    if (this.consumofixo !== 0) {
      this.consumoanual = this.consumofixo * 12;
      // tslint:disable-next-line: max-line-length
      this.barChartData[0].data = [this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo, this.consumofixo];
      this.kwhdia = this.consumofixo / 30;
      console.log(this.kwhdia)
    }
    // tslint:disable-next-line: no-unused-expression
    if (this.consumofixo === 0) {
      // tslint:disable-next-line: max-line-length
      this.consumoanual = parseFloat(this.jan) + parseFloat(this.fev) + parseFloat(this.mar) + parseFloat(this.abr) + parseFloat(this.mai) + parseFloat(this.jun) + parseFloat(this.jul) + parseFloat(this.ago) + parseFloat(this.set) + parseFloat(this.out) + parseFloat(this.nov) + parseFloat(this.dez);
      this.kwhdia = this.consumoanual / (12 * 30);
      this.consumofixo = this.consumoanual / 12;
      // tslint:disable-next-line: max-line-length
      //this.data.datasets[1].data = [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.out, this.nov, this.dez];
      this.barChartData[0].data = [this.jan, this.fev, this.mar, this.abr, this.mai, this.jun, this.jul, this.ago, this.set, this.out, this.nov, this.dez];

    }
  }
  filterCountry(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.nwestados.length; i++) {
        let estadox = this.nwestados[i];
        if (estadox['code'].toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(estadox);
        }
    }
    this.filteredEstados = filtered;
}

filterCidade(event) {
  //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
  let filtered : any[] = [];
  let query = event.query;

  for(let i = 0; i < this.nwcidades.length; i++) {
      let cidadex = this.nwcidades[i];
      if (cidadex['code'].toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(cidadex);
      }
  }
  this.filteredCidades = filtered;
}


  cidadecaller(uf) {
    this.cidades = [];
    this.temperaturas = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.radiajson.length; i++) {
      if (uf.code === this.radiajson[i]['UF']) {
        this.estadoselecionado = this.radiajson[i]['UF']
        this.cidades.push(this.radiajson[i]['CIDADE']);
      }
    }
    this.cidades = this.cidades.sort();
    this.cidades = this.cidades.filter(
      (thing, i, arr) => arr.findIndex(t => t === thing) === i
    );
    for (let i = 0; i < this.cidades.length; i++) {
      this.nwcidades.push({name:this.cidades[i], code:this.cidades[i] });
    }
  }
  

  
  getFlooredFixed(v, d) {
    this.radiacaoescolhida =  (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}


  radiacaocaller(a) {
    var cida = a.name
    console.log(cida)
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.radiajson2.length; i++) {
      if (cida === this.radiajson2[i]['CIDADE'].toUpperCase() && this.estadoselecionado === this.radiajson2[i]['UF']) {
        console.log(this.radiajson2[i]['CIDADE'].toUpperCase() )
        this.cidadeescolhida = this.radiajson2[i]['CIDADE'].toUpperCase() 
        console.log(this.radiajson2[i]['CIDADE'].toUpperCase() )
        //this.radiacaoescolhida = (this.radiajson2[i]['anual'].toFixed(1));
        this.getFlooredFixed(this.radiajson2[i]['anual'],1) 
      }
    }
    this.service.getemperatura()
      // tslint:disable-next-line: deprecation
      .subscribe(dados => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < dados.length; i++) {
          // tslint:disable-next-line: max-line-length
          if (dados[i]['des_pro'] === (this.cidadeescolhida) || dados[i]['des_pro'] === (this.cidadeescolhida + ' - ' + this.estadoselecionado)) {
            this.semtemperatura = false;
            this.t1 = parseFloat(dados[i]['tem_m01']);
            this.t2 = parseFloat(dados[i]['tem_m02']);
            this.t3 = parseFloat(dados[i]['tem_m03']);
            this.t4 = parseFloat(dados[i]['tem_m04']);
            this.t5 = parseFloat(dados[i]['tem_m05']);
            this.t6 = parseFloat(dados[i]['tem_m06']);
            this.t7 = parseFloat(dados[i]['tem_m07']);
            this.t8 = parseFloat(dados[i]['tem_m08']);
            this.t9 = parseFloat(dados[i]['tem_m09']);
            this.t10 = parseFloat(dados[i]['tem_m10']);
            this.t11 = parseFloat(dados[i]['tem_m11']);
            this.t12 = parseFloat(dados[i]['tem_m12']);
          }
        }
        if (this.t1 === 0) { this.semtemperatura = true; }
      })

    this.service.getradiacaoglobal()
      .subscribe(dados => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < dados.length; i++) {
          if (this.cidadeescolhida === dados[i]['CIDADE'] && this.estadoselecionado === dados[i]['UF']) {
            this.rad1 = dados[i]['JAN'] / 1000;
            this.rad2 = dados[i]['FEB'] / 1000;
            this.rad3 = dados[i]['MAR'] / 1000;
            this.rad4 = dados[i]['APR'] / 1000;
            this.rad5 = dados[i]['MAY'] / 1000;
            this.rad6 = dados[i]['JUN'] / 1000;
            this.rad7 = dados[i]['JUL'] / 1000;
            this.rad8 = dados[i]['AUG'] / 1000;
            this.rad9 = dados[i]['SEP'] / 1000;
            this.rad10 = dados[i]['OCT'] / 1000;
            this.rad11 = dados[i]['NOV'] / 1000;
            this.rad12 = dados[i]['DEC'] / 1000;
          }
        }
      });

  }

}
