import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProemanagerService } from './proemanager.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuardamobileGuard implements CanActivate {
  constructor(private proemanagerService: ProemanagerService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('tokenx'); // ou de onde quer que você obtenha o token
    const tokenx = localStorage.getItem('tokenx');

    if (token) {
      // Se houver um token, chame loginAutomatico para verificar
      return this.proemanagerService.loginAutomatico(token).pipe(
        map((loginBemSucedido) => {
          if (loginBemSucedido) {
            // Se o login automático foi bem-sucedido, permita o acesso à rota
            return true;
          } else {
            // Se o login automático falhou, redirecione para a página de login
            this.router.navigate(['/login']);
            return false;
          }
        })
      );
    } else {
      // Se não houver token, redirecione para a página de login
      this.router.navigate(['/login']);
      return false;
    }
  }
}
