import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { ProemanagerService } from './proemanager.service';
import { HttpClientModule } from '@angular/common/http';
import { MyhomolsComponent } from './myhomols/myhomols.component';
import { FilesizePipe } from './filesize.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts';
import {ToolbarModule} from 'primeng/toolbar';
import { ToolbarComponent } from './toolbar/toolbar.component';
import {MenubarModule} from 'primeng/menubar';
import {SidebarModule} from 'primeng/sidebar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';
import {PanelModule} from 'primeng/panel';
import {TableModule} from 'primeng/table';
import {DataViewModule} from 'primeng/dataview';
import {DropdownModule} from 'primeng/dropdown';
import { DetailviewComponent } from './detailview/detailview.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {TimelineModule} from 'primeng/timeline';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { StorageComponent } from './storage/storage.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {SplitterModule} from 'primeng/splitter';
import {ListboxModule} from 'primeng/listbox';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { NgxImageDisplayModule } from '@creativeacer/ngx-image-display';
import {DialogModule} from 'primeng/dialog';
import { NzImageModule } from 'ng-zorro-antd/image';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MatDialogModule} from '@angular/material/dialog';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from "ngx-spinner";
import {MenuModule} from 'primeng/menu';
import {AvatarModule} from 'primeng/avatar';
import { MyacComponent } from './myac/myac.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { ChatComponent } from './chat/chat.component';
import { VistoriaComponent } from './vistoria/vistoria.component';
import { DebitosComponent } from './debitos/debitos.component';
import { NewhomolComponent } from './newhomol/newhomol.component';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzCardModule } from 'ng-zorro-antd/card';
import { CookieService } from 'ngx-cookie-service';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {GMapModule} from 'primeng/gmap';
import { MapsComponent } from './maps/maps.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxCaptureModule } from 'ngx-capture';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { environment } from '../environments/environment';
import {TooltipModule} from 'primeng/tooltip';
import { FileSaverModule } from 'ngx-filesaver';
import { Downloader } from '@ionic-native/downloader/ngx';
import { HomeComponent } from './home/home.component';
import { LottieModule } from 'ngx-lottie';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TreeModule} from 'primeng/tree';
import {PaginatorModule} from 'primeng/paginator';
import {ChartModule} from 'primeng/chart';
import {InputNumberModule} from 'primeng/inputnumber';
import { CodeInputModule } from 'angular-code-input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NzResultModule } from 'ng-zorro-antd/result';
import {PasswordModule} from 'primeng/password';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import player from 'lottie-web';
import { DimensionadorComponent } from './dimensionador/dimensionador.component';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { ChatService } from './chat.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ClimanagerComponent } from './climanager/climanager.component';
import { ButtonModule as ButtonModule2 } from '@syncfusion/ej2-angular-buttons';
import {CheckBoxModule as CheckBoxModule2 } from '@syncfusion/ej2-angular-buttons';
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ToolbarModule   as ToolbarModule2 } from '@syncfusion/ej2-angular-navigations';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { DialogModule as DialogModule2 } from '@syncfusion/ej2-angular-popups';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import {WebcamModule} from 'ngx-webcam';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { MessagesModule } from 'primeng/messages';
import { DateFormatPipe } from './date-format.pipe';

const antDesignIcons = AllIcons as {[key: string]: IconDefinition;};
const config: SocketIoConfig = { url: 'http://sollux.dev', options: {} };

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
export function playerFactory() {return player;}

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    LoginComponent,
    MyhomolsComponent,
    DetailviewComponent,
    StorageComponent,
    FilesizePipe,
    MyacComponent,
    ChatComponent,
    VistoriaComponent,
    DebitosComponent,
    NewhomolComponent,
    MapsComponent,
    HomeComponent,
    DimensionadorComponent,
    ClimanagerComponent,
    DateFormatPipe
  ],
imports: [
  MessagesModule,
  ChipModule,
  TagModule,
  WebcamModule,
  TabAllModule,
  TabModule,
  ButtonModule2,
  CheckBoxModule2,
  ContextMenuModule,
  ToolbarModule2,
  FileManagerAllModule,
  DialogModule2,
  UploaderModule,
  BrowserModule,
  LottieModule.forRoot({ player: playerFactory }),
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  FormsModule,
  RouterModule,
  RouterModule,
  BrowserAnimationsModule,
  ToolbarModule,
  MenubarModule,
  SidebarModule,
  InputTextModule,
  ButtonModule,
  CheckboxModule,
  CardModule,
  DividerModule,
  PanelModule,
  TableModule,
  DataViewModule,
  DropdownModule,
  TabMenuModule,
  TabViewModule,
  FileUploadModule,
  TimelineModule,
  ProgressSpinnerModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  FlexLayoutModule,
  FontAwesomeModule,
  SplitterModule,
  ListboxModule,
  VirtualScrollerModule,
  NgxImageDisplayModule,
  DialogModule,
  NzImageModule,
  SplitButtonModule,
  MatDialogModule,
  BrowserAnimationsModule,
  ScrollingModule,
  DragDropModule,
  NgxSpinnerModule,
  MenuModule,
  AvatarModule,
  NzAvatarModule,
  NzCommentModule,
  NzDescriptionsModule,
  NzPageHeaderModule,
  NzCardModule,
  ReactiveFormsModule,
  ProgressBarModule,
  InputTextareaModule,
  NzIconModule,
  FileSaverModule,
  GMapModule,
  LeafletModule,
  NgxCaptureModule,
  ConfirmDialogModule,
  TooltipModule,
  AutoCompleteModule,
  TreeModule,
  PaginatorModule,
  ChartModule,
  InputNumberModule,
  ChartsModule,
  CodeInputModule,
  MatFormFieldModule,
  NzResultModule,
  PasswordModule,
  NzEmptyModule,
  SocketIoModule.forRoot(config)
  ],
  
  providers: [ChatService,Downloader,FirebaseX, ProemanagerService, CookieService, { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }],
  bootstrap: [AppComponent]
})
export class AppModule { }
