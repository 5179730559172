import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dropbox } from 'dropbox';
import { DbxAuth } from './configs';
import { Router, Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { Subscription } from 'rxjs';

import { LocalStorageMethods } from './utils';
import { ProemanagerService } from './proemanager.service';

@Injectable({
    providedIn: 'root'
})
export class FilesService {
    private dbxAuth: any;
    private subscription: Subscription;
    stream;
    dbx;
    deletedItems = [];
    new_array = []
    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private service: ProemanagerService
    ) {
        this.service.currentdAuth.subscribe(dados => {
            if (dados) {
              this.dbxAuth = dados;
            }
          });
        this.stream = new BehaviorSubject([]);
        this.dbx = new Dropbox({ accessToken: this.dbxAuth.accessToken });
    }

    getFiles(path) {
        console.log(decodeURI(path));
        if (path === '/') {
            path = '';
        }
        this.dbx.filesListFolder({ path: decodeURI(path) })
            .then(response => {
                const entries = response.entries;
                entries.push(entries[0])
                console.log(entries)
                var newentries = this.moveLastArrayElementToFirstIndex(entries)
                console.log(newentries)
                this.stream.next(newentries);
            });

    }
//Moves last element in an array to the front
moveLastArrayElementToFirstIndex(this_array) {
	//this_array.splice(0,0,this_array[this_array.length-1]);
	//this_array.pop();
	 this.new_array = []
	this.new_array[0] = this_array[this_array.length-1]; //first element is last element    
	for(let i=1; i <this_array.length; i++) { //subsequent elements start at 1
		this.new_array[i] = this_array[i-1];
	}
    this.new_array[0]={
    ".tag": "folder",
    "id": "id:opMWwZHerFAAAAAAAAAFNQ",
    "name": ".....",
    "path_display": "/Aplicativos/homofold/HL1592180235/Documentos_tecnicos",
    "path_lower": "/aplicativos/homofold/hl1592180235/documentos_tecnicos",
    "starred": false}
    console.log(this.new_array[0])
	return this.new_array;
}


    deleteFile(path) {
        this.dbx.filesDeleteV2({ path: decodeURI(path) })
            .then(response => {
                this.getFiles(path.substring(0, path.lastIndexOf('/')));
                this.deletedItems.push(response.metadata.name);
                alert('O arquivo foi deltetado com sucesso!');
                localStorage.setItem('items', JSON.stringify(this.deletedItems));
               /*  const data = JSON.parse(localStorage.getItem('items')); */
            }, error => {
                console.log(error);
            });
    }

    // new method added by Kenneth 'Kuken' Nilsson
    deletedData(): Array<any> {
        return JSON.parse(localStorage.getItem('items')) || [];
    }

    // New methods added by K
    searchResults(): Array<any> {
        return LocalStorageMethods.retrieve('search-results') || [];
    }

    favaritesResults(): Array<any> {
        return LocalStorageMethods.retrieve('entries') || [];
    }
}

