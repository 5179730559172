import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Dropbox } from 'dropbox';
import { ProemanagerService } from '../proemanager.service';
import { CookieService } from 'ngx-cookie-service';

import * as $ from 'jquery';

import { FileItem, FileUploader } from 'ng2-file-upload';
import { FORMS } from '../delcare/fomu';
import { kitconfiguracao } from '../delcare/kit';
import {  MenuItem } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
declare const mudatexto: any;
declare const upa1: any;
declare const generate: any;


export interface Locais {
  name: string;
  code: string;
}
export interface Tipos {
  name: string;
  code: string;
}
export interface Cons {
  name: string;
  code: string;
}
export interface CONFIGURACAO {
  modeloplaca: string;
  marcaplaca: string;
  qtdplaca: number;
  potplaca: number;
  modeloinv: string;
  marcainv: string;
  qtdinv: number;
  potinv: number;
}

export interface MODULOMODELO {
  modelo: string;
  codice2: string;
}

@Component({
  selector: 'app-newhomol',
  templateUrl: './newhomol.component.html',
  styleUrls: ['./newhomol.component.css']
})
export class NewhomolComponent implements OnInit {
  formGroup: FormGroup;
  potnominal = 0;
  potpico = 0;
  uploader: FileUploader;
  uploader2: FileUploader;

  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  selectedValue1: string;
  selectedValue2: string;
  selectedValue3: string;
  mod: MODULOMODELO[] = [];
  areasub = [
    { name: 'Aéreo', code: 'Aéreo' },
    { name: 'Subterrâneo', code: 'Subterrâneo' },

  ];
  locais: Locais[] = [
    { name: 'Telhado', code: 'Telhado' },
    { name: 'Laje', code: 'Laje' },
    { name: 'Solo', code: 'Solo' },
    { name: 'Grama seca', code: 'Grama seca' }
  ];
  tipos: Tipos[] = [
    { name: 'Telhado Cerâmico', code: 'Telhado Cerâmico' },
    { name: 'Telha Fibrocimento', code: 'Telha Fibrocimento' },
    { name: 'Telhado Metálico', code: 'Telhado Metálico' },
    { name: 'Telhado Plástico', code: 'Telhado Plástico' },
    { name: 'Laje', code: 'Laje' },
    { name: 'Grama', code: 'Grama' },
    { name: 'Asfalto', code: 'Asfalto' },
    { name: 'Concreto', code: 'Concreto' },
    { name: 'Cascalho', code: 'Cascalho' },
    { name: 'Solo', code: 'Solo' }
  ];

  public simnao: Object[] = [
    { name: 'Sim', code: 'Sim' },
    { name: 'Não', code: 'Não' }
  ];

  enviado = false;
  mapaenviado = false;
  naoenviado = false;
  ultprogup: number;
  lasti = 0;
  configurcao = [];
  validaupload = 'false';
  Cons: any[];
  filesUp: any[];
  esconde = false;
  concessionaria: string;
  selecionadacons: string;
  homologacao = 'HL';
  homol = '';
  aumentocarga: string = null;
  datanagora = '';
  URL: string;
  pdf = false;
  dialogo: any;
  marcamodulos = '';
  dadoshomol = [];
  camponull = false;
  formm = false;
  conc = [];
  links = [];
  fomssaved: FORMS[];
  id: any;
  kits: kitconfiguracao[];
  lastindex: number;
  distanciaPlacaInversor: number = null;
  distanciaInversorQuadro: number = null;
  descricaodokit: string = null;
  dadosolicitante: any;
  solicitante: string;
  novaid: string;
  marcainv2: string;
  modeloinv2: string;
  potinv2: number;
  qtdinv2: number;
  marcainv3: string;
  modeloinv3: string;
  potmodulo: number;
  potinv3: number;
  qtdinv3: number;
  marcainv4: string;
  modeloinv4: string;
  potinv4: number;
  qtdinv4: number;
  marcainv5: string;
  modeloinv5: string;
  potinv5: number;
  qtdinv5: number;
  codhomolocacao: string;
  bairro: string;
  numerocliente: string;
  nome: string;
  ruacliente: string;
  cep: string;
  municipiocliente: string;
  cpfCnpj: string;
  eMailcomum: string;
  celcomum: string;
  ramoDeAtividade: string;
  tipoAtividade: string;
  kwpplacas: string;
  Areadasplacas: string;
  localInstalacao: string;
  tipoDeTelhado: string;
  latitude = null;
  longitude = null;
  marcaInversor: string;
  padraoDeEntrada: string;
  disjuntorentrada: string;
  telefoneparceiro: string;
  statusColetaDados: string;
  statusHomologacao: string;
  statusProjeto: string;
  statusVistoria: string;
  modeloPlaca: string;
  entradaAreaSub: string;
  disjuntoraumentocarga: string;
  estado: string;
  aviso: string;
  numclientecemig: string;
  numeroinstalacao: string;
  cargainstalada: string;
  classetensao: string;
  qtdplacas: string;
  qtdinversor: string;
  marcaplacas: string;
  modeloinversor: string;
  potenciainversor: string;
  disjuntorgeracao: string;
  tipodisjgeracao: string;
  tpdaumentoDeCarga = '';
  primeiraparcela: string;
  segundaparcela: string;
  responsavel: string;
  validacao: {};
  validacao3: {};
  complemento: string;
  abriumapa = 'false'
  resourcesLoaded = '';
  linksform = [];
  andamento = 0;
  acarga = '';
  displaymodel= false
  displayfim = false
  settings = {
    defaultStyle: false,
    actions: false,
    attr: {
      class: 'table table-bordered' // this is custom table scss or css class for table
    },
    columns: {
      conessionaria: {
        title: 'Concessionária', filter: false
      },
      link: {
        title: 'Link', filter: false
      }
    }
  };
  val = false;
  // apagar depois
  productForm: FormGroup;
  ACCESS_TOKEN = 'z_fOSBgdnYAAAAAAAAAASIRN7s6CHmaJ81vF0x1PxzHzpA1rLibkGgvlmJCBIB0q';
  dbx = new Dropbox({ accessToken: this.ACCESS_TOKEN });
  // apagar depois
  display = 'false';
  wpp: string;
  meunome: string;
  ctensao: any[];
  pdentrada: any[];
  disjuntores: any[];
  items: MenuItem[];
  mapabase64: string;
  mapafile:any;
  filex: any;
  dataxs= [{vakir:''}];
  criafoldex = false;
  consumo = '';
  agrupado= '';
  coletivo= '';
  rateio= '';
  marcas: any[];
  filteredmarcas: any[];
  public height: string = '220px';
  public navigationPaneSettings: object;
  public hostUrl: string = "https://www.talktunnels.com/api/_functions/FileManager/";
  public ajaxSettings: object;
  public fields: Object = { text: 'tipo', value: 'Id' };
  constructor(
    private service: ProemanagerService, 
    private router: Router,
    private fb: FormBuilder,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService) { 
     
      this.items = [
        {label: 'Deletar todos', icon: 'pi pi-times', command: () => {
            this.uploader.clearQueue();
        
        }}
    ];
      this.ctensao = [
        {name: '127V', code: '127V'},
        {name: '220V', code: '220V'},
        {name: '127V/220V', code: '127V/220V'},
        {name: '120V/240V', code: '120V/240V'},
        {name: '220V/380V', code: '220V/380V'},
        {name: '13.8kV', code: '13.8kV'},
        {name: '11.95V/15kV', code: '11.95V/ 15kV'},
        {name: '13,8V/15kV', code: '13,8V/ 15kV'},
        {name: '34,5 kV', code: '34,5 kV'},
        {name: '21 kV', code: '21 kV'},
        {name: '69kV', code: '69kV'}
    ];
    this.pdentrada = [
      {name: 'Monofásico', code: 'Monofásico'},
      {name: 'Bifásico', code: 'Bifásico'},
      {name: 'Trifásico', code: 'Trifásico'}
  ];
  this.disjuntores = [
    {name: '10', code: '10'},
    {name: '16', code: '16'},
    {name: '20', code: '20'},
    {name: '25', code: '25'},
    {name: '30', code: '30'},
    {name: '32', code: '32'},
    {name: '40', code: '40'},
    {name: '50', code: '50'},
    {name: '60', code: '60'},
    {name: '63', code: '63'},
    {name: '70', code: '70'},
    {name: '80', code: '80'},
    {name: '100', code: '100'},
    {name: '125', code: '125'},
    {name: '150', code: '150'},
    {name: '150', code: '150'},
    {name: '200', code: '200'},
    {name: '225', code: '225'},
    {name: '250', code: '250'},
    {name: '300', code: '300'},
    {name: '400', code: '400'},
    {name: 'Não Padronizado', code: 'Não Padronizado'},

];

  
  this.uploader = new FileUploader({
    url: '',
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => {
      return new Promise((resolve, reject) => {
        resolve({
          name: item._file.name,
          length: item._file.size,
          contentType: item._file.type,
          date: new Date()
        });
      });
    }
  });
  this.hasBaseDropZoneOver = false;
  this.hasAnotherDropZoneOver = false;
  this.response = '';
  this.uploader.response.subscribe(res => this.response = res);

    }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
mostradialog(){
  this.spinner.hide()
  this.displayfim = true
}

mostradialog2(){
  this.spinner.hide()
  this.displaymodel = true
}
  /////////////////////////////////////////////////////////
  //  BUSCA MODELO MODULOS BANCO DE DADOS
  //////////////////////////////////////////////////////////
  modelomodulos(mar) {
    this.mod = [];
    this.service.modelomodu({ marca: mar, pot: this.potmodulo })
      .subscribe(dados => {
        if (dados !== this.mod) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < dados.length; i++) {
            this.mod.push({ modelo: dados[i]['modelo'], codice2: dados[i]['codice2'] })
          }
        }
      });
  }
  

  nformcons() {
    console.warn(
      this.productForm.value);
  }
  roteamento(){
    this.router.navigateByUrl('/myhomols');
  }

  mutedisplay() {
    this.display = 'true'
  }

  abrealerta() {
    document.getElementById("myCheck").click();
  }
  ngOnInit() {
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.meunome && dados !== '') {
        this.meunome = dados;
      }
    });
    this.service.currentwpp.subscribe(dados => {
      if (dados !== this.wpp && dados !== '') {
        this.wpp = dados;
      }
    });
    this.service.currentelatitude.subscribe(nome => this.latitude = nome);
    this.service.currentelongitude.subscribe(nome => this.longitude = nome);
    this.service.currentabriumapa.subscribe(nome => {
    if (nome !== this.abriumapa && nome !== '') 
      {
        this.abriumapa = nome;
        if (this.abriumapa === 'true') { 
          
          this.atualizarform() }
      }
    });
    for (let i = 0; i < 5; i++) {
      // tslint:disable-next-line: no-unused-expression
      this.configurcao.push({
        potinv: null,
        qtdinv: null,
        modeloinv: null,
        marcainv: null,
        marcaplaca: null,
        qtdplaca: null,
        modeloplaca: null,
        potplaca: null
      });
    }
    this.descricaodokit = ""

    this.datanagora = (((Date.now()) / 1000).toFixed(0)).toString()
    this.homol = this.homologacao + this.datanagora;
    this.service.currentNome.subscribe(nome => this.solicitante = nome);
    this.service.currentid.subscribe(nome => this.id = nome);
    /* Initiate the form structure */
    this.productForm = this.fb.group({
      title: [],
      selling_points: this.fb.array([this.fb.group({
        qtdmodulos: null,
        marcamodulos: null,
        modelomodulos: null,
        potenciamodulo: null,
        qtdinv: null,
        marcainv: null,
        modeloinv: null,
        potenciinv: null
      })]),
      dadosinstal: this.fb.group({
        digeral: new FormControl(this.disjuntorentrada, Validators.required),
        distanciaplacaquadro: new FormControl(this.distanciaPlacaInversor, Validators.required),
        distanciainversorquadro: new FormControl(this.distanciaInversorQuadro, Validators.required),
        padraoentrada: new FormControl(this.padraoDeEntrada, Validators.required),
        entradaareasub: new FormControl(this.entradaAreaSub, Validators.required),
        longitude: new FormControl(this.longitude, Validators.required),
        latitude: new FormControl(this.latitude, Validators.required),
        classetensao: new FormControl(this.classetensao, Validators.required),
        localinst: new FormControl(this.localInstalacao, Validators.required),
        tipotelhado: new FormControl(this.tipoDeTelhado, Validators.required),
        aumentocarga: new FormControl(this.disjuntoraumentocarga),
        tipoaumentocarga: new FormControl(this.tpdaumentoDeCarga),
        consumo:  new FormControl(this.consumo, Validators.required),
        agrupado: new FormControl(this.agrupado, Validators.required),
        coletivo:  new FormControl(this.coletivo, Validators.required),
        rateio: new FormControl(this.rateio, Validators.required),
        acarga: new FormControl(this.acarga, Validators.required),
        descricaodokit: new FormControl(this.descricaodokit, Validators.required),

      }),
    });
    this.service.currentNome.subscribe(dados => {
      if (dados !== this.codhomolocacao && dados !== '') {
        this.resourcesLoaded = dados;
        this.codhomolocacao = dados;
      }
    });

    this.service.dowconcessionarias()
      .subscribe(dados => {
        if (dados !== this.Cons) {
          this.Cons = dados;
        }
      });

    this.service.gsolicitante({ ID: this.id })
      .subscribe(dados => {
        if (dados !== this.dadosolicitante) {
          this.dadosolicitante = dados;

        }
      });

  }

  get sellingPoints() {
    return this.productForm.get('selling_points') as FormArray;
  }
  //calcfv(pot) {
  //  this.potenciamodulos(pot);
  //}
  potenciamodulos(potencia) {
    this.marcas = [];
    this.filteredmarcas = [];
    this.potmodulo = potencia;
    this.service.potmod({ pot: potencia })
      .subscribe(dados => {
        if (dados !== this.marcas) {
          var marc = dados.sort();
        }
        for(let i = 0; i < marc.length; i++) {
          this.marcas.push({name:marc[i],code:marc[i]})
          this.filteredmarcas.push({name:marc[i],code:marc[i]})
        }
        
      });
  }


 dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  addSellingPoint() {
    this.lasti = this.lasti + 1;
    this.sellingPoints.push(this.fb.group({
      qtdmodulos: '',
      marcamodulos: '',
      modelomodulos: '',
      potenciamodulo: '',
      qtdinv: '',
      marcainv: '',
      modeloinv: '',
      potenciinv: ''
    }));
  }
  teste() {
  }
  deleteSellingPoint(index) {
    this.sellingPoints.removeAt(index);
    this.lasti = this.lasti - 1;

  }

  dataChanged(event) {
    this.concessionaria = event;
    this.puxauploads();
  }
  puxauploads() {
    this.service.arquivosnecessarios({ cons: this.concessionaria })
      .subscribe(dados => {
        if (dados !== this.filesUp) {
          this.filesUp = dados;
        }
      });
  }

  idmuda(i) {
    this.URL = this.links[i];
  }

  closemodal() {
    this.nloadFile();
  }


  mostralinks() {
    this.formm = true;
  }

  nloadFile() {
    // tslint:disable-next-line: max-line-length
    generate({
      ncliente: this.numclientecemig,
      ninstal: this.numeroinstalacao,
      titular: this.nome,
      classe: this.ramoDeAtividade,
      cpf: this.cpfCnpj,
      rua: this.ruacliente,
      numerorua: this.numerocliente,
      bairro: this.bairro,
      cep: this.cep,
      municpio: this.municipiocliente,
      estado: this.estado,
      email: this.dadosolicitante.emailparceiro,
      lat: this.latitude,
      long: this.longitude,
      ci: this.cargainstalada,
      de: this.disjuntorentrada,
      te: this.classetensao,
      da: this.disjuntorentrada,
      potgeracao: this.potenciainversor,
      qtdmod: this.qtdplacas,
      fabmod: this.marcaplacas,
      modmodu: this.modeloPlaca,
      potmod: this.kwpplacas,
      qtdinv: this.qtdinversor,
      fabinv: this.marcaInversor,
      modinv: this.modeloinversor,
      potinv: this.potenciainversor,
      area: this.Areadasplacas,
      celular: this.dadosolicitante.celparceiro,
      complemento: this.complemento,
      linkdoc: this.URL
    });
  }

  onUserRowSelect(event): void {
    this.URL = event.data['link']
    this.nloadFile();
  }

  atualizarform() {
    this.service.currentForm.subscribe(dados => {
      if (dados !== this.fomssaved) {
        this.fomssaved = dados;
        this.disjuntorentrada = dados['diger'];
        this.entradaAreaSub = dados['ramal'];
        this.padraoDeEntrada = dados['tipoentrada'];
        this.classetensao = dados['classetensao'];
        this.disjuntoraumentocarga = dados['aumentocarga'];
        this.tpdaumentoDeCarga = dados['tipoaumento'];
        this.distanciaPlacaInversor = dados['distinv'];
        this.distanciaInversorQuadro = dados['distinvquadro'];
        this.localInstalacao = dados['localinst'];
        this.tipoDeTelhado = dados['tipoinst'];
        this.descricaodokit = dados['descricao'];

      }
    });
  }

  abremapa() {
    this.service.changemapa('true');
    this.cookieService.set( 'diger', (this.productForm.value.dadosinstal.entradaAreaSub) );
    var formu = {
      diger: this.productForm.value.dadosinstal.digeral,
      ramal: this.productForm.value.dadosinstal.entradaareasub,
      tipoentrada: this.productForm.value.dadosinstal.padraoentrada,
      classetensao: this.productForm.value.dadosinstal.classetensao,
      aumentocarga: this.productForm.value.dadosinstal.aumentocarga,
      tipoaumento: this.productForm.value.dadosinstal.tipoaumentocarga,
      distinv: this.productForm.value.dadosinstal.distanciaplacaquadro,
      distinvquadro: this.productForm.value.dadosinstal.distanciainversorquadro,
      localinst: this.productForm.value.dadosinstal.localinst,
      tipoinst: this.productForm.value.dadosinstal.tipotelhado,
      descricao: this.productForm.value.dadosinstal.descricaodokit,

      
    }
    this.service.mudaform(formu);
    this.router.navigateByUrl('/maps');
  }

  asyncUpload(i) {
    return new Promise((resolve, reject) => {
      this.dbx.filesUpload({ path: "/" + this.homol + "/" + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[i]['_file'] })
        .then(response => {
          resolve();
          this.enviado = true;
          this.uploader.queue[i]['isUploaded'] = true;
          this.uploader.queue[i]['isSuccess'] = true;
          if (i === this.uploader.queue.length -1){
            this.mapafileupload
          }
        })
        .catch(error => reject())
        .finally(() => this.progresso());
    });
   
  }
  progresso() {
    var index = 0;
    this.ultprogup = 100 / (this.uploader.queue.length);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i]['isSuccess'] === true) {
        this.andamento = this.ultprogup * (i + 1);
      }
    }
    if (this.andamento === 100) {

      if(this.mapafile){

        this.mapafileupload()
      }
      if(!this.mapafile){
        this.solicitahomologa() 
      }
    }
  }


  mapafileupload() {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.dbx.filesUpload({ path: "/" + this.homol + "/" + this.mapafile.name, contents: this.mapafile })
        .then(response => {
          resolve();
        })
        .catch(error => reject())
        .finally(() => this.solicitahomologa() 
        );
    });
  }


  resetatudo() {
    this.enviado = false;
    this.naoenviado = false;
    this.ultprogup = 0;
  }

  async uploadalgo() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      await this.asyncUpload(i);
    }
  }

  clicabotao(){
    this.service.currentBae64Source.subscribe(dados => {
      if (dados !=='0') {
        this.mapabase64 = dados;
        this.mapafile = this.dataURLtoFile(this.mapabase64,'print_mapa.png');
        this.uploadalgo()
      }
    });
    let element: HTMLElement = document.getElementById('file-input') as HTMLElement;
    element.click(); 
   }

  rota() {
    this.router.navigateByUrl('/homologacoes');
  }

  solicitahomologa() {
    if (this.disjuntoraumentocarga === undefined) {
      this.aumentocarga = '0';
    }
    if (this.disjuntoraumentocarga !== undefined) {
      this.aumentocarga = this.disjuntoraumentocarga;
    }
    for (let i = 0; i < this.productForm.value.selling_points.length; i++) {
      this.potnominal = this.potnominal + parseFloat(this.productForm.value.selling_points[i].potenciinv)
      this.potpico = this.potpico + parseFloat(this.productForm.value.selling_points[i].potenciamodulo)

    }
    this.potnominal = 0;
    this.potpico = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = this.productForm.value.selling_points.length; i < 5; i++) {
      this.productForm.value.selling_points.push({
        qtdmodulos: null,
        marcamodulos: null,
        modelomodulos: null,
        potenciamodulo: null,
        qtdinv: null,
        marcainv: null,
        modeloinv: null,
        potenciinv: null
      })
    }

    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    this.service.novahomologacao({
      token: localStorage.getItem('tokenx'),
      ID: this.id,
      nome: 'Coletando informações',
      endereco: '...',
      cep: '...',
      cidade: 'Coletando informações',
      uf: 'Coletando informações',
      rg: '...',
      cpf: '...',
      nomeparceiro: this.dadosolicitante.nick.toUpperCase(),
      emailparc: this.dadosolicitante.emailparceiro,
      homologato: this.homol,
      email: this.dadosolicitante.emailparceiro,
      cel: this.dadosolicitante.celparceiro,
      classe: '...',
      atividade: '...',
      potkwp: this.potpico,
      area: '...',
      localinst: this.productForm.value.dadosinstal.localinst,
      tipotelhado: this.productForm.value.dadosinstal.tipotelhado,
      inclinacao: '...',
      coords: '...',
      orientacao: '...',
      descricao: this.productForm.value.dadosinstal.descricaodokit,
      distanciaplacaquadro: this.productForm.value.dadosinstal.distanciaplacaquadro,
      distanciainversorquadro: this.productForm.value.dadosinstal.distanciainversorquadro,
      padraoentrada: this.productForm.value.dadosinstal.padraoentrada,
      digeral: this.productForm.value.dadosinstal.digeral,
      telefone: '...',
      codhomol: this.homol,
      statusdados: 'Pendente',
      statusprojeto: 'Pendente',
      statushomologacao: 'Pendente',
      statusvistoria: 'Pendente',
      modeloplaca1: this.productForm.value.selling_points[0].modelomodulos,
      entradaareasub: this.productForm.value.dadosinstal.entradaareasub,
      datainicio: dateinic2,
      time1: dateinic2,
      time2: '',
      time3: '',
      time4: '',
      time1f: '',
      time2f: '',
      time3f: '',   
      time4f: '',
      datafim: '...',
      art: '...',
      disjuntoraumentocarga: this.productForm.value.dadosinstal.aumentocarga,
      solicitante: this.dadosolicitante.nick.toUpperCase(),
      iddono: this.id,
      aviso: '...',
      bairro: '...',
      longitude: this.productForm.value.dadosinstal.latitude,
      latitude: this.productForm.value.dadosinstal.longitude,
      numerocliente: '...',
      numeroinstalacao: '...',
      numclientecemig: '...',
      classetensao: this.productForm.value.dadosinstal.classetensao,
      qtdplaca1: this.productForm.value.selling_points[0].qtdmodulos,
      marcaplaca1: this.productForm.value.selling_points[0].marcamodulos,
      disjuntorgeracao: '...',
      tipodisjgeracao: '...',
      tpdaumentodeCarga: this.productForm.value.dadosinstal.tipoaumentocarga,
      cargainstalada: '...',
      pagamentoentrada: 'Pendente',
      pagamentosegundaparte: 'Pendente',
      responsavel: '...',
      complemento: '...',
      marcainv1: this.productForm.value.selling_points[0].marcainv,
      qtdinv1: this.productForm.value.selling_points[0].qtdinv,
      modeloinv1: this.productForm.value.selling_points[0].modeloinv,
      potinv1: this.productForm.value.selling_points[0].potenciinv,
      marcainv2: this.productForm.value.selling_points[1].marcainv,
      modeloinv2: this.productForm.value.selling_points[1].modeloinv,
      qtdinv2: this.productForm.value.selling_points[1].qtdinv,
      potinv2: this.productForm.value.selling_points[1].potenciinv,
      marcainv3: this.productForm.value.selling_points[2].marcainv,
      modeloinv3: this.productForm.value.selling_points[2].modeloinv,
      qtdinv3: this.productForm.value.selling_points[2].qtdinv,
      potinv3: this.productForm.value.selling_points[2].potenciinv,
      marcainv4: this.productForm.value.selling_points[3].marcainv,
      modeloinv4: this.productForm.value.selling_points[3].modeloinv,
      qtdinv4: this.productForm.value.selling_points[3].qtdinv,
      potinv4: this.productForm.value.selling_points[3].potenciinv,
      marcainv5: this.productForm.value.selling_points[4].marcainv,
      modeloinv5: this.productForm.value.selling_points[4].modeloinv,
      qtdinv5: this.productForm.value.selling_points[4].qtdinv,
      potinv5: this.productForm.value.selling_points[4].potenciinv,
      potenominalusina: this.potnominal,
      potplaca1: this.productForm.value.selling_points[0].potenciamodulo,
      marcaplaca2: this.productForm.value.selling_points[1].marcamodulos,
      modeloplaca2: this.productForm.value.selling_points[1].modelomodulos,
      qtdplaca2: this.productForm.value.selling_points[1].qtdmodulos,
      potplaca2: this.productForm.value.selling_points[1].potenciamodulo,
      marcaplaca3: this.productForm.value.selling_points[2].marcamodulos,
      modeloplaca3: this.productForm.value.selling_points[2].modelomodulos,
      qtdplaca3: this.productForm.value.selling_points[2].qtdmodulos,
      potplaca3: this.productForm.value.selling_points[2].potenciamodulo,
      marcaplaca4: this.productForm.value.selling_points[3].marcamodulos,
      modeloplaca4: this.productForm.value.selling_points[3].modelomodulos,
      qtdplaca4: this.productForm.value.selling_points[3].qtdmodulos,
      potplaca4: this.productForm.value.selling_points[3].potenciamodulo,
      marcaplaca5: this.productForm.value.selling_points[4].marcamodulos,
      modeloplaca5: this.productForm.value.selling_points[4].modelomodulos,
      qtdplaca5: this.productForm.value.selling_points[4].qtdmodulos,
      potplaca5: this.productForm.value.selling_points[4].potenciamodulo,
      consumo: this.productForm.value.dadosinstal.consumo,
      agrupado:this.productForm.value.dadosinstal.agrupado,
      coletivo: this.productForm.value.dadosinstal.coletivo,
      rateio:this.productForm.value.dadosinstal.rateio,
      acarga: this.productForm.value.dadosinstal.acarga
    })
      .subscribe(dados => {
        if (dados) {
          //this.service.changeBase64('0')
          this.novatarefa();
          this.enviouhomol()
          this.solhomol()
          this.mostradialog()
        }
      });
    this.validacao3 = {}
  }

  criasfold(){
    this.service
    .criafolder({
      path: '/',
      myid:this.id,
      name: this.homol,
      data: [{name: this.homol,
      size: '4.00 KB',
      isFile: false,
      dateModified: '2022-05-08T13:59:26.003Z',
      dateCreated: '2022-05-08T13:59:26.003Z',
      type: '',
      filterPath: '/',
      permission: null,
      hasChild: true,
      _fm_id: 'fe_tree'}
      ]})
    .subscribe((dados) => {
      if (dados ) {
        this.dataxs = dados
       if(this.dataxs['vakir'] ==='ok'){
        this.navigationPaneSettings = { maxWidth: '850px', minWidth: '140px', visible: false};
        this.ajaxSettings = {
          path2: this.homol,
          url: this.hostUrl + 'ap',
          showHiddenItems: true,
          getImageUrl: this.hostUrl + 'GetImage',
          uploadUrl: this.hostUrl + 'Upload',
          downloadUrl: this.hostUrl + 'Download'
      };
        this.solicitahomologa() 
        this.criafoldex = false
       }
 
      }
    })
  }
  novatarefa() {
    const dateinici=  new Date(Date.now()).toLocaleString('pt-BR')
    const dateinic2 = dateinici.split(' ')[0];
    this.service.newtaskhomol({
      descricao: 'Coletando informações iniciais para elaboração do projeto',
      status: 'Em andamento',
      datainicio: dateinic2,
      datafim: 'Aguardando',
      responsavel: 'Sollux Engenharia',
      codhomol: this.homol,
    })
      .subscribe(dados => {
        if (dados !== this.validacao3) {
          this.validacao3 = dados;
          if (dados['status'] === 'cadastrado') {
          }
          if (dados['status'] === 'erro') {
          }
        }
      });
  }

  enviouhomol() {
    var grupo = '553892515427-1590502958'
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtext({
      sessionName: 'sollux',
      number: grupo,
      text: '⚠️  O parceiro ' + '*' + this.meunome + '*' + ' Solicitou uma nova homologação com número ' + '*' + this.homol + '*' + ' .Favor iniciar coleta de dados'
    })
      .subscribe(dados => {
        if (dados) {
        }
      });
  }

  solhomol() {
    var grupo = '553892515427-1590502958'
    var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtextuni({
      sessionName: 'sollux',
      number: this.wpp,
      text: '⚠️ Caro parceiro rebemos sua homologação de número ' + '*' + this.homol + '*' + ' .Iremos iniciar a elaboração da documentação inicial e lhe manteremos informados'
    })
      .subscribe(dados => {
        if (dados) {
        }
      });
  }

  abremapas(){
    this.router.navigateByUrl('/maps');  
  }
}
