import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Dropbox } from 'dropbox';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProemanagerService } from '../proemanager.service';
import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import ImageModule from 'open-docxtemplater-image-module';
import { todosdados } from '../delcare/todosdados';
import { MenuItem } from 'primeng/api';
declare const salvablob: any;



function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function replaceErrors(key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (error,key) {
      error[key] = value[key];
      return error;
    },
      {});
  }
  return value;
}

function base64DataURLToArrayBuffer(dataURL) {
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, "");
  let binaryString;
  if (typeof window !== "undefined") {
    binaryString = window.atob(stringBase64);
  } else {
    // = Buffer.from(stringBase64, "base64").toString("binary");
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}
@Component({
  selector: 'app-vistoria',
  templateUrl: './vistoria.component.html',
  styleUrls: ['./vistoria.component.css']
})
export class VistoriaComponent implements OnInit {
// DROPBOX UPLOAD
wpp: string;
meunome: string;
andamento = 0;

uploader: FileUploader;
hasBaseDropZoneOver: boolean;
hasAnotherDropZoneOver: boolean;
response: string;
validaupload = 'false';
validacaoemail = {};
validacao3 = {};
ultprogup: number;
enviado = false;
naoenviado = false;
displayfim = false;
// apagar depois
productForm: FormGroup;
// ACCESS_TOKEN = 'z_fOSBgdnYAAAAAAAAAB3s5UdGqdkvu-4vrIxgErF10X1ysLyIZdHnnaO6iflABM';
ACCESS_TOKEN = 'z_fOSBgdnYAAAAAAAAAASIRN7s6CHmaJ81vF0x1PxzHzpA1rLibkGgvlmJCBIB0q';
oquedevoenviar= false;
dbx = new Dropbox({ accessToken: this.ACCESS_TOKEN });
// apagar depois

dialogo: any;
vistorias: todosdados[];
vistoriaslecionada: string;
todashomologacoes: todosdados[];
id: string;
filtredvisto: any;
image;
dadosolicitante: any;
aviso = false;
solicitou = false;
items: MenuItem[];
salva:any;
@ViewChild('labelImport', { static: false })
labelImport: ElementRef;

formImport: FormGroup;
fileToUpload: File = null;

constructor(private service: ProemanagerService,
            private router: Router,
            private spinner: NgxSpinnerService) {
              this.items = [
                {label: 'Deletar todos', icon: 'pi pi-times', command: () => {
                    this.uploader.clearQueue();
                }}
            ];
    
  this.salva =  localStorage.getItem('outera')
  this.formImport = new FormGroup({
    importFile: new FormControl('', Validators.required)
  });
  this.uploader = new FileUploader({
    url: '',
    disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item) => {
      return new Promise((resolve, reject) => {
        resolve({
          name: item._file.name,
          length: item._file.size,
          contentType: item._file.type,
          date: new Date()
        });
      });
    }
  });
  this.hasBaseDropZoneOver = false;
  this.hasAnotherDropZoneOver = false;
  this.response = '';
  this.uploader.response.subscribe(res => this.response = res);
}
public fileOverBase(e: any): void {
  this.hasBaseDropZoneOver = e;
}
public fileOverAnother(e: any): void {
  this.hasAnotherDropZoneOver = e;
}
asyncUpload(i) {
  return new Promise((resolve, reject) => {
    // tslint:disable-next-line: prefer-for-of
    // tslint:disable-next-line: max-line-length
    this.dbx.filesUpload({ path: "/" + this.vistoriaslecionada + "/fotos_vistoria" + "/" + this.uploader.queue[i]['_file'].name, contents: this.uploader.queue[i]['_file'] })
      // tslint:disable-next-line: only-arrow-functions
      .then(response => {
        this.enviado = true;
        this.uploader.queue[i]['isUploaded'] = true;
        this.uploader.queue[i]['isSuccess'] = true;
        resolve();
      })
      .catch(error => reject())
      .finally(() => this.progresso());
  });
}
progresso() {
  this.ultprogup = 100 / (this.uploader.queue.length);
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < this.uploader.queue.length; i++) {
    if (this.uploader.queue[i]['isSuccess'] === true) {
      this.uploader.progress = this.ultprogup * (i + 1);
      this.andamento = this.ultprogup * (i + 1);

    }
  }

  if (this.andamento === 100) {
    this.spinner.show();
    this.novavisto();    
  }
}
clicabotao(){
  let element: HTMLElement = document.getElementById('file-input') as HTMLElement;
  element.click(); 
 }
resetatudo() {
  this.enviado = false;
  this.naoenviado = false;
  this.ultprogup = 0;
}
async uploadalgo() {
  for (let i = 0; i < this.uploader.queue.length; i++) {
    await this.asyncUpload(i);
  }
}
ngOnInit() {
  this.service.currentwpp.subscribe(dados => {
    if (dados !== this.wpp && dados !== '') {
      this.wpp = dados;
    }
  });

  this.service.currentNome.subscribe(dados => {
    if (dados !== this.meunome && dados !== '') {
      this.meunome = dados;
    }
  });
  this.service.currentid.subscribe(dados => {
    if (dados !== this.id && dados !== '') {
      this.id = dados;
      this.puxahomologacoes();
    }
    this.service.gsolicitante({ ID: this.id })
      .subscribe(dats => {
        if (dats !== this.dadosolicitante) {
          this.dadosolicitante = dats;
        }
      });
  });
}

puxahomologacoes() {
  this.service.myvistoria({ ID: this.id })
    .subscribe((data: todosdados[]) => {
      if (data !== this.vistorias) {
        this.vistorias = data;
      }
    });
}

filtravistoria(){
  if(this.vistoriaslecionada === undefined){  }  else {
  this.service.filtravisto({ ID: this.vistoriaslecionada })
      .subscribe((data) => {
        if (data !== this.filtredvisto) {
          this.filtredvisto = data;
          this.solicitou = true;
          this.aviso = true;
        }
      });
    }
}


roteamento(){
  this.router.navigateByUrl('/myhomols');
}

novatarefa() {
  this.enviouvisto()
  this.solvisto()
  //this.solhomol()
  const dateinici = new Date(Date.now()).toLocaleString('pt-BR');
  this.service.newtaskhomol({
    token: localStorage.getItem('tokenx'),
    descricao: 'Vistoria solicitada no sistema, aguardando check digitalizado',
    status: 'Em andamento',
    datainicio: dateinici,
    datafim: 'Aguardando',
    responsavel: 'Sollux Engenharia',
    codhomol: this.vistoriaslecionada,
  })
  .subscribe(dados => {
    if (dados !== this.validacao3) {
      this.validacao3 = dados;
      if (dados['status'] === 'cadastrado') {
        this.upavisto();
      }
      if (dados['status'] === 'erro') {
      }
    }
  });
}

upavisto(){
// tslint:disable-next-line: max-line-length
let val: any;
this.service.upastatusvisto({
  token: localStorage.getItem('tokenx'),
  statusvistoria: 'Solicitada pelo cliente' ,
  codhomol: this.vistoriaslecionada })
.subscribe((data) => {
  if (data !== val) {
    val = data;
    //this.geravistoria();
  }
});
}


solvisto() {
  var grupo = '553892515427-1590502958'
  var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
  //for (let i = 0; i < fones.length; i++) {
  this.service.sendtextuni({
    sessionName: 'sollux',
    number: this.wpp,
    text: '⚠️  Caro parceiro recebemos seu pedido de vistoria para homologação ' + '*' + this.filtredvisto[0].codhomol + ' - ' + this.filtredvisto[0].Cliente + '*' + ' \n 📁 *Não se esqueça de anexar as fotos na pasta do cliente para validar essa solicitação*'
  })
    .subscribe(dados => {
      if (dados) {
      }
    });
}
novavisto(){
  // tslint:disable-next-line: max-line-length
  this.service.solicitavisto({
    token: localStorage.getItem('tokenx'),
    cliente: this.filtredvisto[0].Cliente , 
    email: this.dadosolicitante['emailparceiro'] ,
    homol: this.vistoriaslecionada })
      .subscribe((data) => {
        if (data !== this.validacaoemail) {
          this.validacaoemail = data;
          this.novatarefa();
        }
      });
    }
geravistoria() {
  this.spinner.show()
  const nomeclientes = this.filtredvisto[0].Cliente;
  const enderecos = this.filtredvisto[0].local;
  const codhomols = this.filtredvisto[0].codhomol;
  const datainicios = this.filtredvisto[0].datainicio;
  const cidades = this.filtredvisto[0].cidade;
  const estados = this.filtredvisto[0].estado;
  const datafims = new Date(Date.now()).toLocaleString('pt-BR');
  const cnpjs = this.filtredvisto[0].cpf;
  const potepicos = this.filtredvisto[0].potkwp;
  const potenominals = this.filtredvisto[0].potnom;
  // tslint:disable-next-line: max-line-length
  const enderecopars =  this.dadosolicitante.ruaparceiro + ' - ' + this.dadosolicitante.numruaparceiro + ' - ' + this.dadosolicitante.bairroparceiro + ' - ' + this.dadosolicitante.cidadeparceiro + ' - ' + this.dadosolicitante.ufparceiro;
  const cnpjpars = this.dadosolicitante.cnpjparceiro1;
  const emailpars = this.dadosolicitante.emailparceiro;
  const parceiros = this.dadosolicitante.nomeparceiro1;
  const arts = this.filtredvisto[0].art;
  const visto = this.vistoriaslecionada;
  const linkdoc = 'assets/doc/vistoria.docx'
  // tslint:disable-next-line: only-arrow-functions
  loadFile(linkdoc, function (error,content) {
    if (error) {
      throw error;
    }
    const imageOpts = {
      getImage(tag) {
        return base64DataURLToArrayBuffer(tag);
      },
      getSize() {
        return [480, 340];
      }
    };
    const imageModule = new ImageModule(imageOpts);
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, { modules: [imageModule] });
    doc.setData({
      nomecliente: nomeclientes,
      endereco: enderecos,
      codhomol: codhomols,
      datainicio: datainicios,
      datafim: datafims,
      cnpj: cnpjs,
      potepico: potepicos,
      potenominal: potenominals,
      enderecopar: enderecopars,
      cnpjpar: cnpjpars,
      emailpar: emailpars,
      art: arts,
      cidade: cidades,
      estado: estados,
      parceiro: parceiros
    });
    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      doc.render();
    } catch (error) {


      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation;
          })
          .join("\n");
        // errorMessages is a humanly readable message looking like this :
        // 'The tag beginning with "foobar" is unopened'
      }
      throw error;
    }
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    });
    salvablob(out, visto + '_checklist.docx')
})
setTimeout(()=>{   
  this.spinner.hide();                      
  this.displayfim = true
}, 3000);
}

enviouvisto() {
  var grupo = '553892515427-1590502958'
  var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
  //for (let i = 0; i < fones.length; i++) {
  this.service.sendtext({
    sessionName: 'sollux',
    number: grupo,
    text: '⚠️  O parceiro ' + '*' + this.filtredvisto[0].solicitante + '*' + ' Solicitou vistoria para homologação ' + '*' + this.filtredvisto[0].codhomol + ' - ' + this.filtredvisto[0].Cliente + '*' 
  })
    .subscribe(dados => {
      if (dados) {
      }
    });
}



solhomol() {
  var grupo = '553892515427-1590502958'
  var fones = ['5538992515427', '5538991437790', '553899666526', '5538988271752']
  //for (let i = 0; i < fones.length; i++) {
  this.service.sendtextuni({
    sessionName: 'sollux',
    number: this.wpp,
    text: '⚠️ Recebemos sua solicitação de vistoria para homologação ' + '*' + this.filtredvisto[0].codhomol + ' - ' + this.filtredvisto[0].Cliente + '*' + ' .Iremos por favor preencha o checklist(se for o caso) gerado pelo Datasol e anexe para que sua solicitação seja concluída'
  })
    .subscribe(dados => {
      if (dados) {
      }
    });
}
}