import { Component, OnInit } from '@angular/core';
import { ProemanagerService } from '../proemanager.service';

@Component({
  selector: 'app-debitos',
  templateUrl: './debitos.component.html',
  styleUrls: ['./debitos.component.css']
})
export class DebitosComponent implements OnInit {

  id: string;
  debitos = [];
    constructor( private service: ProemanagerService) { }
  
    ngOnInit() {
      this.service.currentid.subscribe(dados => {
        if (dados !== this.id && dados !== '') {
          this.id = dados;
          console.log(this.id);
          this.puxdebitos();
        }
      });
    }

  
  downloadMyFile(a){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', a);
    link.setAttribute('download', "Boleto Homologação " );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
    
    
  
    puxdebitos() {
      this.service.Debitos({ ID: this.id })
        .subscribe((data) => {
          if (data) {
            this.debitos = data;
            console.log(this.debitos);
          }
        });
    }

}
