import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ID } from '../delcare/id';
import { ProemanagerService } from '../proemanager.service';
declare const crenditialssollux: any;
import * as CryptoJS from 'crypto-js';
declare var Fileplugin: any;
declare var jQuery:  any;
declare var teste: any;
declare var writeFile: any;
import jwt_decode from 'jwt-decode';
declare var requestFileSystem: any;
import 'node_modules/jquery/dist/jquery.min.js';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  senhaformcontrol = new FormControl('', [
    Validators.required,
  ]);
  matcher = new MyErrorStateMatcher();

  emailrecu = '';
  display = false;
  k1= '';
  k2= '';
  k3= '';
  errologin = false;
  nregistrado = false;
  registrad = false;
  valido = false;
  naovalido = true;
  registrado = false;
  dados= [];
  url= '';
  filelogin: any;
  idcliente= '';
  message= '';
  nome= '';
  ID:any;
  decodedToken: any;
  loga = false;
  senha= '';
  logou = false
  mostrapin = false;
  resultado= false;
  emailinformado = '';
  trocasenha1= '';
  trocasenha2= '';
  mudsenha = false;
  codex: any;
  validacao = [
    { id: 1, number: 'Valido' },
    { id: 2, text: 'ID' },
    { id: 2, text: 'nome' },
    { id: 2, text: 'pic' }
  ];
  color = 'black';
  mode = 'indeterminate';
  value = 100;
  customStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #7e7e7e",
    borderRadius: "50%",
    color: "#7e7e7e",
    cursor: "pointer"
  };
  title = 'Sollux-engenharia';
  dadosolicitante: any;
  versao = '1.0.5';
  escondeu = true;
  usuario= '';
  credentials: any;
  tokenization:any;
  bytes:any
  displaypin= false;
  mostratex=false;
  autenticado = false;
  public loading = false;
  constructor(private service: ProemanagerService,private router: Router,
    private config: PrimeNGConfig) { 
      this.config.setTranslation({
        accept: 'Aceito',
        reject: 'Cancelar',
        startsWith: "Começa com",
        contains: "Contém",
        notContains: "Não contém",
        endsWith: "Termina com",
        passwordPrompt: "Insira uma senha",
        equals: "Igual",
        weak:"Fraca",
        medium:"Média",
        strong:"Forte",
        notEquals: "Diferente",
        noFilter: "Sem filtro",
        lt: "Menor que",
        lte: "menor ou igual",
        gt: "Maior que",
        gte: "Maior ou igual",
        is: "É",
        isNot: "Não é",
        before: "Antes",
        after: "Depois",
        clear: "Limpar",
        apply: "Aplicar",
        matchAll: "Match All",
        matchAny: "Match Any",
        addRule: "Add regra",
        removeRule: "Remover regra",
        choose: "Escolher",
        upload: "Upload",
        cancel: "Cancelar",
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do","Se","Te","Qa","Qi","Sx","Sa"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        weekHeader: "Wk"
        //translations
    });
    }

  ngOnInit(): void {
    const tokenx = localStorage.getItem('tokenx');
    this.decodedToken  = jwt_decode(tokenx);
    this.service.loginAutomatico(tokenx).subscribe(
      (loginBemSucedido) => {
        if (loginBemSucedido) {
        } else {
        }
      }
    );
  }


  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    if(this.codex === code){
      this.mostrapin = false
      this.mudsenha = true;
    }
  }
  recupera() {
    this.service.recuperasenha({ email: this.emailrecu})
      .subscribe(dados => {
        if (dados ) {
            this.decryptData2(dados['cod'])
        }
      });
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }
  

  onClick() {
    this.loga = true;
    this.service.gettok({ email: this.usuario, senha: this.senha })
      .subscribe(dados => {
        if (dados) {
          this.tokenization = dados
          localStorage.setItem('tokenx', this.tokenization['token']);
          this.autoriza()
        }
      });
  }
  enviacomenta(a,b){
    var grupo = '553892515427-1612924425'
    var fones = ['5538992515427','5538991437790','553899666526','5538988271752']
    //for (let i = 0; i < fones.length; i++) {
    this.service.sendtextuni({
      sessionName: 'sollux',
      number: a ,
      text: '🔒  Caro parceiro,foi solicitado um reset de senha para sua conta.\n\n Digite o seguinte código no Datasol: ' + '*'+ b + '* ' + '.\n\nCaso não tenha feito essa solicitação entre imediatamente em contato com a Sollux Engenharia!'
     })
      .subscribe(dados => {
        if (dados['status'] === 'Enviado') {
          this.mostrapin = true;
        }
      });
     
  }
  decryptData2(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, 'teste');
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        this.enviacomenta(dadox['wpp'], dadox['cod'])
        this.codex = (dadox['cod']).toString();
      }
      return data;
    } catch (e) {
    }
  }
  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, 'teste');
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        this.codex = (dadox['cod']).toString();
        this.display = true;
        this.mostrapin = true;
        this.usuario = this.credentials['nome']
        this.usuario = this.credentials['pass']
        this.onClick()
      }
      return data;
    } catch (e) {
    }
  }
  codifica (data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), '').toString();
    } catch (e) {
    }
  }
  decryptx(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '');
      if (bytes.toString()) {
        var dadox = (JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
        this.usuario = (dadox['usuario']).toString();
        this.senha = (dadox['senha']).toString();
      }
      return data;
    } catch (e) {
    }
  }
  upasenha() {
    this.service.upasenha2({
      senha: this.trocasenha2,
      email: this.emailrecu,
    })
      .subscribe((data) => {
        if (data['status'] === 'atualizado') {
          this.mostrapin = false;
          this.mudsenha = false;
          this.resultado = true;
 
        }
      });
  }

  autoriza(){
    const tokenx = localStorage.getItem('tokenx');
    this.service.loginauth({
      email: this.usuario, 
      senha: this.senha
    })
    .subscribe(dados => {
      if (dados) {
        //crenditialssollux({kod:this.codifica({ name: this.usuario,pass: this.senha})});
        this.ID = dados;
        this.url = this.ID.pic['pic'];
        this.nome = this.ID['nome']
        this.service.changeid(this.ID['ID']);
        this.service.changeNome(this.ID['nome']);
        this.service.changeurl(this.ID['pic']);
        this.service.changewpp(this.ID['wpp']);
        this.gomyhomol();
      }
    });
  }

  gomyhomol() {
    if (this.ID['Valido'] === 1) {
      localStorage.setItem('emaildatasol', this.usuario);
      localStorage.setItem('senhadatasol', this.senha);
      this.naovalido = false;
      localStorage.setItem('email', this.usuario);
      localStorage.setItem('senha', this.senha);
      this.service.validalogin('true');
      this.router.navigateByUrl('/myhomols');
      this.valido = true;
      // tslint:disable-next-line: max-line-length
    } else { this.errologin = true; }
  }
}
